import { Box, Group, LoadingOverlay, Paper, Text } from "@mantine/core";
import * as React from "react";

import classes from "./StatCard.module.css";

type StatCardProps = {
  icon: React.ElementType;
  stat: {
    title: string;
    value: string;
    diff: number;
  };
  isLoading?: boolean;
};

export const StatCard = ({ stat, icon: Icon, isLoading }: StatCardProps) => {
  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Paper withBorder p="md" radius="md" key={stat.title}>
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>{stat.value}</Text>
          {stat.diff === 0 ? null : (
            <Text
              c={stat.diff > 0 ? "teal" : "red"}
              fz="sm"
              fw={500}
              className={classes.diff}
            >
              <span>+{stat.diff}</span>
            </Text>
          )}
        </Group>

        <Text fz="xs" c="dimmed" mt={7}>
          Porównanie do poprzedniego miesiąca
        </Text>
      </Paper>
    </Box>
  );
};
