import {
  Box,
  Button,
  Card,
  CloseButton,
  Group,
  Select,
  Title,
  Tooltip,
} from "@mantine/core";
import * as React from "react";

import { BrandsMultiSelect } from "./BrandsMultiSelect";
import { ProductSearchInput } from "./ProductSearchInput";
import { SearchableCategoriesInput } from "./SearchableCategoriesInput";

type Filter = {
  name: string;
  value: string[];
};

type ProductsTableActionsProps = {
  onFilterChange: (categories: Filter | Filter[] | null) => void;
  title: string;
  subTitle: React.ReactNode;
  defaultValues: {
    name: string;
    value: string[];
  }[];
};

export const ProductsTableActions = ({
  onFilterChange,
  title,
  subTitle,
  defaultValues = [],
}: ProductsTableActionsProps) => {
  const categoryIds = defaultValues.find((item) => item.name === "categoryId");
  const brandIds = defaultValues.find((item) => item.name === "brandId");
  const productSearch = defaultValues.find((item) => item.name === "name/ean");

  return (
    <Box>
      <Title>{title}</Title>
      {subTitle}
      <Card shadow="sm" padding="xs" radius="md" withBorder>
        <Group align="center" justify="start" gap={2}>
          <Title size="h4">Filtry</Title>
          {defaultValues.some((filter) => filter.value.length > 0) ? (
            <Tooltip label="Wyczyść wszystkie filtry">
              <CloseButton
                size="sm"
                onClick={() => {
                  onFilterChange(
                    defaultValues.map((item) => ({
                      name: item.name,
                      value: [],
                    })),
                  );
                }}
                aria-label="Clear value"
              />
            </Tooltip>
          ) : null}
        </Group>
        <ProductSearchInput
          onChange={onFilterChange}
          defaultValue={productSearch?.value?.[0]}
          key={productSearch?.value?.[0]}
        />
        <Group align="center">
          <div style={{ width: 300 }}>
            <SearchableCategoriesInput
              key={categoryIds?.value?.join(",")}
              label="Kategorie"
              placeholder="Wybierz kategorie"
              multi
              onChange={onFilterChange}
              defaultValue={categoryIds?.value}
            />
          </div>
          <BrandsMultiSelect
            key={brandIds?.value?.join(",")}
            onChange={onFilterChange}
            defaultValue={brandIds?.value}
          />
          <Select
            label="Znajdź duplikaty"
            data={[
              { value: "ean", label: "EAN" },
              { value: "name", label: "Nazwa" },
            ]}
            clearable
            onChange={(value) => {
              if (!value) {
                onFilterChange({
                  name: "findDuplicates",
                  value: [],
                });
              } else {
                onFilterChange({
                  name: "findDuplicates",
                  value: [value],
                });
              }
            }}
          />
        </Group>
      </Card>
    </Box>
  );
};
