import * as React from "react";
import { useForm } from "@mantine/form";
import { Button, Input, Paper, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import { useAdminUpsertLocationCategoryMutation } from "../../../graphql/graphql";

export const ContextualDiscoveryNewCategoryForm = () => {
  const form = useForm({
    initialValues: {
      name: "",
    },
  });

  const { mutateAsync } = useAdminUpsertLocationCategoryMutation();

  const handleSubmit = async (values) => {
    try {
      await mutateAsync({
        input: {
          name: values.name,
        },
      });
      notifications.show({
        color: "green",
        title: "Utworzono nową kategorię",
        message: "Kategoria została utworzona.",
      });
    } finally {
      form.reset();
    }
  };
  return (
    <Paper shadow="md" withBorder className="p-4">
      <Text>Formularz tworzenia nowej kategorii</Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Input placeholder="Piekarnie" {...form.getInputProps("name")} />

        <Button type="submit" className="mt-2">
          Dodaj nową kategorię
        </Button>
      </form>
    </Paper>
  );
};
