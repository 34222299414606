import * as React from "react";
import { useParams } from "react-router-dom";

import { CenterLoader } from "../../../components";
import { useAdminUserQuery } from "../../graphql/graphql";

export const UserPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data: user, isLoading } = useAdminUserQuery({
    id: userId,
  });
  return isLoading ? (
    <CenterLoader />
  ) : (
    <div>
      <h1>User Page</h1>
      <pre>{JSON.stringify(user, null, 2)}</pre>
    </div>
  );
};
