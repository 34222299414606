import { Image } from "@mantine/core";
import * as React from "react";

export const NewFileImage = ({ file }: { file: File }) => {
  const [fileUrl, setFileUrl] = React.useState<string | null>(null);
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => {
    setFileUrl(fileReader.result as string);
  };

  return <Image maw={240} radius="md" alt="Uploaded image" src={fileUrl} />;
};
