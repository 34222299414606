import * as React from "react";
import { useAdminCreateMerchantMutation } from "../../graphql/graphql";
import { useForm } from "@mantine/form";
import { Button, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";

export const CreateMerchantPage = () => {
  const { mutate: createMerchantMutate, isLoading } =
    useAdminCreateMerchantMutation({
      onSuccess: (data) => {
        if (data) {
          notifications.show({
            color: "green",
            title: "Utworzono nowego merchanta",
            message: "Konto nowego merchanta zostało utworzone, wysłano email.",
          });
        } else {
          notifications.show({
            color: "red",
            title: "Błąd podczas tworzenia merchanta",
            message: "Nie udało się utworzyć konta merchanta.",
          });
        }

        form.reset();
      },
    });

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      taxId: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Niepoprawny email"),
      taxId: (value) => (/^\d{10}$/.test(value) ? null : "Niepoprawny NIP"),
      name: (value) =>
        value.split(" ").every((text) => text.length > 3)
          ? null
          : "Imię i nazwisko wymagane",
    },
  });

  const createMerchant = (values) => {
    createMerchantMutate({
      input: values,
    });
  };

  return (
    <>
      <h1>Tworzenie nowego merchanta</h1>
      <form onSubmit={form.onSubmit(createMerchant)}>
        <TextInput required label="Nazwa" {...form.getInputProps("name")} />
        <TextInput required label="Email" {...form.getInputProps("email")} />
        <TextInput required label="NIP" {...form.getInputProps("taxId")} />
        <Button type="submit" loading={isLoading} disabled={isLoading}>
          Stwórz merchanta
        </Button>
      </form>
    </>
  );
};
