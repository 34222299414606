import cn from "classnames";
import * as React from "react";

type HeaderProps = {
  children: React.ReactNode;
  outerClassNames?: string;
  innerClassNames?: string;
  onClick?: () => void;
};

export const Header = ({
  children,
  outerClassNames,
  innerClassNames,
  onClick,
}: HeaderProps) => {
  const outerClasses = cn(
    "leading-relaxed font-extrabold tracking-tighter",
    outerClassNames,
  );
  const innerClasses = cn(
    "bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent flex items-center",
    innerClassNames,
  );
  return (
    <div className={outerClasses} onClick={onClick}>
      <div className={innerClasses}>{children}</div>
    </div>
  );
};
