import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

const MyArrayParam = withDefault(ArrayParam, []);

export const useUsersTableQuerySearch = () => {
  const [query, setValue] = useQueryParams({
    name: StringParam,
  });

  return {
    query,
    updateQuery: setValue,
  };
};
