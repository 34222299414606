import { IonicAuthOptions } from "@ionic-enterprise/auth";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { debounce } from "@zozia/fp";
import { SessionProvider } from "@zozia/react-auth";
import { ConfirmDeleteDialogContainer } from "@zozia/ui";
import * as React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { PrivateRoute } from "./components/PrivateRoute";
import { Shell } from "./components/Shell";
import { LoginForm } from "./components/Shell/LoginForm";
import { AnalyticsIndexPage } from "./features/analytics";
import {
  BrandIndexEditPage,
  BrandIndexPage,
  BrandNamesIndexPage,
  BrandsIndexPage,
} from "./features/brands";
import { BusinessesIndexPage } from "./features/businesses";
import { BusinessIndexPage } from "./features/businesses/pages/BusinessIndexPage";
import {
  ContextualDiscoveriesIndexPage,
  ContextualDiscoveryIndexPage,
} from "./features/contextual-discovery";
import { EmailsPage } from "./features/emails";
import { LocationUpsertPage, LocationsIndexPage } from "./features/locations";
import { CreateMerchantPage } from "./features/merchant/pages/CreateMerchantPage";
import {
  CategoriesIndexRouteUrl,
  CategoryEditIndexRouteUrl,
  CategoryIndexRouteUrl,
  ProductCategoriesIndexPage,
  ProductCategoryEditIndexPage,
  ProductCategoryIndexPage,
} from "./features/product-categories";
import {
  ProductSuggestionsDetailsIndexPage,
  ProductSuggestionsIndexPage,
  ProductSuggestionsIndexRouteUrl,
} from "./features/product-suggestions";
import {
  MultipleProductUpdateTablePage,
  ProductUpsertPage,
  ProductsIndexPage,
} from "./features/products";
import { ProductsNewSerialPage } from "./features/products/pages/ProductsNewSerialPage";
import { TaxesPage } from "./features/tax";
import { UserPage, UsersPage } from "./features/users";
import { queryClient } from "./lib/query-client";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Shell />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        element: <AnalyticsIndexPage />,
      },
      {
        path: "collections/merchants/create",
        element: <CreateMerchantPage />,
      },
      {
        path: `collections/${ProductSuggestionsIndexRouteUrl}`,
        element: <ProductSuggestionsIndexPage />,
      },
      {
        path: `collections/${ProductSuggestionsIndexRouteUrl}/:productSuggestionId`,
        element: <ProductSuggestionsDetailsIndexPage />,
      },
      {
        path: `collections/taxes`,
        element: <TaxesPage />,
      },
      {
        path: "collections/products",
        element: <ProductsIndexPage />,
      },
      {
        path: "collections/products/multipleUpdate",
        element: <MultipleProductUpdateTablePage />,
      },
      {
        path: "collections/products/newSerial",
        element: <ProductsNewSerialPage />,
      },
      {
        path: "collections/products/:productId",
        element: <ProductUpsertPage />,
      },
      {
        path: "collections/brandNames",
        element: <BrandNamesIndexPage />,
      },
      {
        path: "collections/brands",
        element: <BrandsIndexPage />,
      },
      {
        path: "collections/brands/:brandId",
        element: <BrandIndexPage />,
      },
      {
        path: "collections/brands/:brandId/edit",
        element: <BrandIndexEditPage />,
      },
      {
        path: "collections/emails",
        element: <EmailsPage />,
      },
      {
        path: "collections/users",
        element: <UsersPage />,
      },
      {
        path: "collections/users/:userId",
        element: <UserPage />,
      },
      {
        path: `collections/${CategoriesIndexRouteUrl}`,
        element: <ProductCategoriesIndexPage />,
      },
      {
        path: `collections/${CategoryIndexRouteUrl}`,
        element: <ProductCategoryIndexPage />,
      },
      {
        path: `collections/${CategoryEditIndexRouteUrl}`,
        element: <ProductCategoryEditIndexPage />,
      },
      {
        path: "collections/locations",
        element: <LocationsIndexPage />,
      },
      {
        path: "collections/locations/:locationId",
        element: <LocationUpsertPage />,
      },
      {
        path: "collections/businesses",
        element: <BusinessesIndexPage />,
      },
      {
        path: "collections/businesses/:businessId",
        element: <BusinessIndexPage />,
      },
      {
        path: "collections/contextual-discoveries",
        element: <ContextualDiscoveriesIndexPage />,
      },
      {
        path: "collections/contextual-discoveries/:contextualDiscoveryId",
        element: <ContextualDiscoveryIndexPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/logincallback",
    element: <div>Logincallback</div>,
  },
]);

const webAuthConfig: IonicAuthOptions = {
  clientID: "zozia-admin-production",
  discoveryUrl:
    "https://auth2.zozia.app/realms/zozia-prod/.well-known/openid-configuration",
  scope: "openid email profile roles",
  audience: "",
  authConfig: "keycloak",
  redirectUri: `${window.location.origin}/logincallback`,
  logoutUrl: `${window.location.origin}/logincallback`,
  platform: "web",
  webAuthFlow: "PKCE",
};

export default function App() {
  return (
    <MantineProvider>
      <Notifications position="top-center" />
      <SessionProvider
        config={webAuthConfig}
        vault="localStorage"
        loadingNode={<span>Loading....</span>}
      >
        <QueryClientProvider client={queryClient}>
          <ConfirmDeleteDialogContainer />
          <ReactQueryDevtools initialIsOpen={false} />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </SessionProvider>
    </MantineProvider>
  );
}
