import { useSession } from "@zozia/react-auth";

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit["headers"],
): ((variables?: TVariables) => Promise<TData>) => {
  const { getAccessToken } = useSession();

  return async (variables?: TVariables) => {
    const isMultipartFormData = variables?.input instanceof FormData;

    try {
      const accessToken = await getAccessToken();
      const response = await fetch(process.env.DATA_SERVICE, {
        method: "POST",
        headers: new Headers({
          ...(isMultipartFormData
            ? {}
            : { "Content-Type": "application/json" }),
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        }),
        body: isMultipartFormData
          ? variables.input
          : JSON.stringify({
              query,
              variables,
            }),
      });
      const json = await response.json();
      return json.data;
    } catch (error) {
      console.log(error);
    }
  };
};
