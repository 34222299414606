import { SimpleGrid } from "@mantine/core";
import {
  IconBuildingStore,
  IconUserPlus,
  IconUsersPlus,
} from "@tabler/icons-react";
import * as React from "react";

import {
  useLocationsCountQuery,
  useProductRequestUpdateCountQuery,
  useProductsCountQuery,
} from "../../graphql/graphql";
import { StatCard } from "../components";
import classes from "./AnalyticsIndexPage.module.css";

const currentMonth = new Date().getMonth() + 1;

const CountCard = ({ field, useQuery, icon }) => {
  const { data, isLoading } = useQuery();

  let diff = 0;

  if (data?.counts[field].diff?.[0]?.count) {
    const currentMonthFromData = parseInt(
      data.counts[field].diff[0].date.split("-")[1],
      10,
    );
    console.log({ currentMonth, currentMonthFromData });
    if (currentMonthFromData !== currentMonth) {
      diff = 0;
    } else {
      diff = data.counts[field].diff[0].count;
    }
  }

  return (
    <StatCard
      isLoading={isLoading}
      stat={{
        title: "Wszystkie produkty",
        value: data?.counts[field].count?.toString() ?? "0",
        diff,
      }}
      icon={icon}
    />
  );
};

export const AnalyticsIndexPage = () => {
  return (
    <div className={classes.root}>
      <SimpleGrid cols={{ base: 1, xs: 2, md: 4 }}>
        <CountCard
          field="product"
          useQuery={useProductsCountQuery}
          icon={IconUsersPlus}
        />
        <CountCard
          field="location"
          useQuery={useLocationsCountQuery}
          icon={IconBuildingStore}
        />
        <CountCard
          field="productRequestUpdate"
          useQuery={useProductRequestUpdateCountQuery}
          icon={IconBuildingStore}
        />
      </SimpleGrid>
    </div>
  );
};
