import * as React from "react";
import { Input, InputBase, Combobox, useCombobox } from "@mantine/core";
import { useMerchantLocationCategoryQuery } from "../../../graphql/graphql";

type LocationCategorySelectProps = {
  defaultValue?: string;
  selectedCategoryIds?: string[];
  onChange?: (categoryId: string) => void;
};

export const LocationCategorySelect = ({
  defaultValue,
  selectedCategoryIds,
  onChange,
}: LocationCategorySelectProps) => {
  const { data, isLoading } = useMerchantLocationCategoryQuery();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [value, setValue] = React.useState<string | null>(defaultValue || "");

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const options = data.merchantLocationCategory
    .filter((cat) => !selectedCategoryIds?.includes?.(cat.id))
    .map((item) => (
      <Combobox.Option value={item.id} key={item.id}>
        {item.name}
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={async (val) => {
        setValue(val);
        await onChange?.(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          type="button"
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
        >
          {data.merchantLocationCategory.find((a) => a.id === value)?.name || (
            <Input.Placeholder>Pick value</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
