import * as React from "react";

type TimeAgoProps = {
  value: number | Date | string;
};

export const timeAgo = (value: number | Date | string): [string, number] => {
  const date = new Date(+value);
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (seconds < 10) {
    return ["przed chwilą", seconds];
  } else if (seconds < 60) {
    return [`${seconds} sekund${seconds === 1 ? "a" : "y"} temu`, seconds];
  } else if (seconds < 60 * 60) {
    const minutes = Math.floor(seconds / 60);
    return [`${minutes} minut${minutes === 1 ? "e" : "y"} temu`, seconds];
  } else if (seconds < 60 * 60 * 24) {
    const hours = Math.floor(seconds / (60 * 60));
    return [`${hours} godzin${hours === 1 ? "e" : "y"} temu`, seconds];
  } else if (seconds < 60 * 60 * 24 * 7) {
    const days = Math.floor(seconds / (60 * 60 * 24));
    return [`${days} ${days === 1 ? "dzień" : "dni"} temu`, seconds];
  } else {
    return [date.toLocaleDateString(), seconds];
  }
};

export const TimeAgo = ({ value }: TimeAgoProps) => {
  const [, update] = React.useState(0);
  const forceUpdate = React.useCallback(() => update((x) => x + 1), []);

  const [time, seconds] = timeAgo(value);
  React.useEffect(() => {
    let t;
    if (seconds < 120) {
      t = setInterval(forceUpdate, 1000);
    }
    return () => clearTimeout(t);
  }, [value, seconds]);

  return time;
};
