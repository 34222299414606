import { ButtonGroup, Stack, TextInput } from "@mantine/core";
import { Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import * as React from "react";

import {
  useAdminDeleteBrandNameMutation,
  useAdminUpsertBrandNameMutation,
} from "../features/graphql/graphql";
import { queryClient } from "../lib/query-client";
import { SearchableBrandNameInput } from "./SearchableBrandNameInput";

type BrandNameUpsertFormProps = {
  name: any;
  brand: any;
  onBrandUpsertSuccess?: () => void;
};

export const BrandNameUpsertForm = ({
  name,
  brand,
  onBrandUpsertSuccess,
}: BrandNameUpsertFormProps) => {
  const form = useForm({
    initialValues: {
      manufacturerId: brand.adminBrand.id,
      brandNameId: name.id,
      name: name.name,
    },
  });

  const { mutate: upsertBrandNameMutation } = useAdminUpsertBrandNameMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([
        "AdminBrand",
        { brandId: brand.adminBrand.id },
      ]);
      onBrandUpsertSuccess?.();
    },
  });
  const { mutate: deleteBrandNameMutation } = useAdminDeleteBrandNameMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([
        "AdminBrand",
        { brandId: brand.adminBrand.id },
      ]);
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        upsertBrandNameMutation({
          input: {
            manufacturerId: brand.adminBrand.id,
            brandNameId: values.brandNameId,
            name: values.name,
          },
        });
      })}
    >
      <pre>{JSON.stringify(form.values, null, 2)}</pre>
      <Stack gap={1} className="w-full">
        <TextInput
          width="100%"
          {...form.getInputProps("name")}
          defaultValue={name.name}
        />
        <SearchableBrandNameInput
          onNew={(brandName) => {
            form.setFieldValue("name", brandName);
          }}
          onChange={({ value }) => {
            form.setFieldValue("brandNameId", value[0]);
          }}
        />
        <ButtonGroup>
          <Button type="submit">Zapisz</Button>
          <Button
            color="red"
            onClick={() => {
              if (name.id) {
                deleteBrandNameMutation({
                  brandNameId: name.id,
                });
              } else {
                onBrandUpsertSuccess?.();
              }
            }}
          >
            Usuń
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};
