import {
  CloseButton,
  Combobox,
  InputBase,
  Pill,
  PillsInput,
  ScrollArea,
  Stack,
  Text,
  useCombobox,
} from "@mantine/core";
import { Tooltip } from "@mantine/core";
import { IconSelector } from "@tabler/icons-react";
import * as React from "react";

import { useProductCategoriesQuery } from "../../../graphql/graphql";

type SearchableCategoriesInputProps = {
  onChange: (categories: { name: string; value: string[] } | null) => void;
  label: string;
  multi?: boolean;
  defaultValue?: string[];
  placeholder?: string;
};

const DEFAULT_CATEGORIES_FILTER = {
  name: "categoryId",
  value: [],
};

export const SearchableCategoriesInput = React.memo(
  ({
    multi,
    label,
    onChange,
    defaultValue,
    placeholder,
  }: SearchableCategoriesInputProps) => {
    const { data } = useProductCategoriesQuery(
      {},
      {
        staleTime: Infinity,
      },
    );
    const categories = data?.productCategories ?? [];
    const selectCategories = categories
      .map(({ id, tree, name }) => {
        if (tree) {
          return tree
            ?.map((id) => categories.find((p) => p.id === id))
            .map((path) =>
              path.id === id
                ? {
                    label: path.name,
                    value: path.id,
                  }
                : {
                    label: `${path.name} >`,
                    value: path.id,
                  },
            );
        }

        return [
          {
            label: name,
            value: id,
          },
        ];
      })
      .filter(Boolean)
      .map((path) => {
        const prevPart = path.map((p) => p.label).join(" ");
        const label = path[path.length - 1].label;

        return {
          prevPart: prevPart === label ? "Kategoria główna" : prevPart,
          label,
          value: path[path.length - 1].value,
        };
      });

    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
      onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
    });

    const [search, setSearch] = React.useState("");
    const [value, setValue] = React.useState<string[]>(defaultValue ?? []);

    const handleValueSelect = (newValue: string) => {
      setSearch("");
      if (multi) {
        setValue((current) => {
          const value = current.includes(newValue)
            ? current.filter((v) => v !== newValue)
            : [...current, newValue];

          onChange(
            value.length > 0
              ? { ...DEFAULT_CATEGORIES_FILTER, value }
              : DEFAULT_CATEGORIES_FILTER,
          );

          return value;
        });
      } else {
        onChange({ ...DEFAULT_CATEGORIES_FILTER, value: [newValue] });
        setValue([newValue]);
      }

      if (!multi) {
        combobox.closeDropdown();
        combobox.targetRef.current?.blur();
      }
    };

    const handleValueRemove = (val: string) =>
      setValue((current) => current.filter((v) => v !== val));

    const values = value.map((item) => (
      <Pill
        key={item}
        withRemoveButton
        onRemove={() => handleValueSelect(item)}
      >
        {categories.find((category) => category.id === item)?.name ?? ""}
      </Pill>
    ));

    const rawOptions = selectCategories
      .filter((item) => (multi ? !value.includes(item.value) : true))
      .filter(
        (item) =>
          item.prevPart
            .toLowerCase()
            .includes(search?.trim()?.toLowerCase() || "") ||
          item.label
            .toLowerCase()
            .includes(search?.trim()?.toLowerCase() || ""),
      );

    const options = rawOptions.map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={value.includes(item.value)}
      >
        <Stack gap={0}>
          <Text fw={700} size="sm">
            {item.label}
          </Text>
          <Text size="xs">{item.prevPart}</Text>
        </Stack>
      </Combobox.Option>
    ));

    return (
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} width={400}>
        {multi ? (
          <Combobox.DropdownTarget>
            <PillsInput
              label={label}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              rightSection={
                value.length > 0 ? (
                  <Tooltip label="Wyczyść wszystkie">
                    <CloseButton
                      size="sm"
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => {
                        setValue([]);
                        onChange(DEFAULT_CATEGORIES_FILTER);
                      }}
                      aria-label="Clear value"
                    />
                  </Tooltip>
                ) : (
                  <IconSelector size="1rem" />
                )
              }
            >
              <Pill.Group>
                {values}

                <Combobox.EventsTarget>
                  <PillsInput.Field
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                    value={search}
                    placeholder={placeholder}
                    onChange={(event) => {
                      combobox.updateSelectedOptionIndex();
                      setSearch(event.currentTarget.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace" && search.length === 0) {
                        event.preventDefault();
                        handleValueRemove(value[value.length - 1]);
                      }
                    }}
                  />
                </Combobox.EventsTarget>
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>
        ) : (
          <Combobox.Target>
            <InputBase
              label={label}
              placeholder={placeholder}
              value={
                combobox.dropdownOpened
                  ? search
                  : categories.find((category) => category.id === value[0])
                      ?.name ?? ""
              }
              onClick={() => {
                combobox.openDropdown();
              }}
              onFocus={() => {
                if (value[0] !== undefined) {
                  setSearch(
                    categories.find((category) => category.id === value[0])
                      ?.name,
                  );
                }
                combobox.openDropdown();
              }}
              onBlur={() => {
                combobox.closeDropdown();
              }}
              onChange={(event) => {
                combobox.updateSelectedOptionIndex();
                setSearch(event.currentTarget.value);
              }}
            />
          </Combobox.Target>
        )}

        <Combobox.Dropdown>
          <Combobox.Options>
            <ScrollArea.Autosize mah={350} type="scroll">
              {options.length > 0 ? (
                options
              ) : (
                <Combobox.Empty>Nie ma takiej kategorii...</Combobox.Empty>
              )}
            </ScrollArea.Autosize>
          </Combobox.Options>
          {multi ? null : (
            <Combobox.Footer>
              <Text fz="xs" c="dimmed">
                Wybierz kategorię do której najbardziej pasuje dodawany produkt
              </Text>
            </Combobox.Footer>
          )}
        </Combobox.Dropdown>
      </Combobox>
    );
  },
  (prev, next) => prev.defaultValue !== next.defaultValue,
);
