import {
  Avatar,
  AvatarGroup,
  Button,
  Center,
  Group,
  Loader,
  Table,
} from "@mantine/core";
import { LoadMore, TimeAgo } from "@zozia/ui";
import * as React from "react";

import { Link } from "../../../components/Link";
import { TableHeaderSortable } from "../../../components/TableHeaderSortable/TableHeaderSortable";
import { OrderBy, useInfiniteAdminProductsQuery } from "../../graphql/graphql";
import { ProductActiveSwitch } from "../components/ProductActiveSwitch";
import { ProductsTableActions } from "../components/ProductsTableActions";
import { useProductsTableQuerySearch } from "../hooks";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const ProductsIndexPage = () => {
  const { updateQuery, query } = useProductsTableQuerySearch();

  const [filters, setFilters] = React.useState([
    { name: "categoryId", value: query.categoryId },
    { name: "brandId", value: query.brandId },
    {
      name: "name/ean",
      value: query.productSearch ? [query.productSearch] : [],
    },
  ]);

  const [orderBy, setOrderBy] = React.useState<OrderBy[]>([
    { field: "updatedAt", descriptor: { sort: "DESC" } },
  ]);

  const updateOrderBy = (orderBy: OrderBy) => {
    setOrderBy((prev) => {
      const existingOrderBy = prev.find(
        (order) => order.field === orderBy.field,
      );

      return [
        ...prev.filter((order) => order.field !== orderBy.field),
        {
          ...orderBy,
          descriptor: {
            sort: existingOrderBy?.descriptor?.sort === "ASC" ? "DESC" : "ASC",
          },
        },
      ];
    });
  };

  const {
    data: infiniteProductsQueryResult,
    fetchNextPage,
    hasNextPage,
    isLoading,
    ...a
  } = useInfiniteAdminProductsQuery(
    {
      input: { filters, orderBy, cursor: null },
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage) =>
        lastPage.adminProducts.cursor
          ? {
              input: {
                filters,
                orderBy,
                cursor: lastPage.adminProducts.cursor,
              },
            }
          : undefined,
    },
  );

  const rows = infiniteProductsQueryResult?.pages
    .flatMap((p) => p.adminProducts.data)
    .map((row) => {
      return (
        <Table.Tr key={row.id}>
          <Table.Td>
            <AvatarGroup>
              {row.images.slice(0, 1).map((image) => (
                <Avatar key={image.url} src={image.url} />
              ))}
              {row.images.length > 1 ? (
                <Avatar>+{row.images.length - 1}</Avatar>
              ) : null}
              {row.images.length === 0 ? <Avatar>Brak</Avatar> : null}
            </AvatarGroup>
          </Table.Td>
          <Table.Td>
            <Link to={`/collections/products/${row.id}`} fz="sm">
              {row.name || "Brak nazwy"}
            </Link>
          </Table.Td>
          <Table.Td>{row.ean}</Table.Td>
          <Table.Td>
            <TimeAgo value={row.updatedAt} />
          </Table.Td>
          <Table.Td>
            <TimeAgo value={row.createdAt} />
          </Table.Td>
          <Table.Td>
            <ProductActiveSwitch productId={row.id} checked={row.active} />
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <ProductsTableActions
          subTitle={
            <Group>
              <Button component={Link} to={`/collections/products/new`}>
                Dodaj nowy produkt
              </Button>
              <Button component={Link} to={`/collections/products/newSerial`}>
                Dodaj nowe produkty seryjnie
              </Button>
              <Button
                component={Link}
                to={"/collections/products/multipleUpdate"}
              >
                Edytuj wiele produktów
              </Button>
            </Group>
          }
          title="Lista produktów"
          defaultValues={filters}
          onFilterChange={(incomingFilters) => {
            incomingFilters = [].concat(incomingFilters).filter(Boolean);

            const exisingFilters = filters.filter(
              (filter) =>
                !(incomingFilters as []).some(
                  (incomingFilter) => filter.name === incomingFilter.name,
                ),
            );

            let newFilters = [...exisingFilters, ...incomingFilters];

            newFilters = newFilters.filter(Boolean);

            updateQuery({
              brandId:
                newFilters.find((f) => f.name === "brandId")?.value ?? [],
              categoryId:
                newFilters.find((f) => f.name === "categoryId")?.value ?? [],
              productSearch:
                newFilters.find((f) => f.name === "name/ean")?.value[0] ?? null,
            });

            setFilters(newFilters);
          }}
        />
      </div>
      {isLoading ? (
        CenterLoader
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th>Nazwa</Table.Th>
              <Table.Th>EAN</Table.Th>
              <TableHeaderSortable
                onChange={(orderBy) => {
                  if (orderBy === "EMPTY") {
                    setOrderBy((prev) =>
                      prev.filter((order) => order.field !== "updatedAt"),
                    );
                  } else {
                    updateOrderBy({
                      field: "updatedAt",
                      descriptor: { sort: orderBy },
                    });
                  }
                }}
                orderBy={
                  orderBy.find((order) => order.field === "updatedAt")
                    ?.descriptor?.sort
                }
              >
                Data aktualizacji
              </TableHeaderSortable>
              <TableHeaderSortable
                onChange={(orderBy) => {
                  if (orderBy === "EMPTY") {
                    setOrderBy((prev) =>
                      prev.filter((order) => order.field !== "createdAt"),
                    );
                  } else {
                    updateOrderBy({
                      field: "createdAt",
                      descriptor: { sort: orderBy },
                    });
                  }
                }}
                orderBy={
                  orderBy.find((order) => order.field === "createdAt")
                    ?.descriptor?.sort
                }
              >
                Data utworzenia
              </TableHeaderSortable>
              <TableHeaderSortable
                onChange={(orderBy) => {
                  if (orderBy === "EMPTY") {
                    setOrderBy((prev) =>
                      prev.filter((order) => order.field !== "createdAt"),
                    );
                  } else {
                    updateOrderBy({
                      field: "active",
                      descriptor: { sort: orderBy },
                    });
                  }
                }}
                orderBy={
                  orderBy.find((order) => order.field === "active")?.descriptor
                    ?.sort
                }
              >
                Aktywny
              </TableHeaderSortable>
            </Table.Tr>
          </Table.Thead>
          <tbody>{rows}</tbody>
        </Table>
      )}
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>{CenterLoader}</div>
        </LoadMore>
      ) : null}
    </>
  );
};
