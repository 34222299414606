import * as React from "react";
import { debounce } from "@zozia/fp";

export const useDebouncedHandler = (callback, ms = 1000) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = React.useMemo(() => {
    const func = (val?: any) => {
      if (ref.current) ref.current?.(val);
    };

    return debounce(func, ms);
  }, []);

  return debouncedCallback;
};
