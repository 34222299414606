import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

const MyArrayParam = withDefault(ArrayParam, []);

export const useProductsTableQuerySearch = () => {
  const [query, setValue] = useQueryParams({
    productSearch: StringParam,
    brandId: MyArrayParam,
    categoryId: MyArrayParam,
  });

  return {
    query,
    updateQuery: setValue,
  };
};
