import { Button, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import * as React from "react";
import { z } from "zod";

import { SearchableTaxesInput } from "../../../../components/SearchableTaxesInput";
import { SearchableCategoriesInput } from "../../../products/components/ProductsTableActions/SearchableCategoriesInput";

export const UpsertCategoryForm = ({ defaultValue, handleSubmit }) => {
  const form = useForm({
    initialValues: defaultValue || {
      name: "",
    },
    validate: zodResolver(
      z.object({
        name: z.string().min(1),
      }),
    ),
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} className="h-[500px]">
      <SearchableCategoriesInput
        defaultValue={[defaultValue?.parentId]}
        label="Kategoria nadrzędna"
        placeholder="Wybierz kategorie"
        onChange={({ value: [selectedCategoryId] }) =>
          form.setFieldValue("parentId", selectedCategoryId)
        }
      />
      <SearchableTaxesInput
        defaultValue={defaultValue?.taxId}
        label="Stawka VAT"
        placeholder="Wybierz stawkę VAT"
        onChange={({ value: selectedTaxId }) =>
          form.setFieldValue("taxId", selectedTaxId)
        }
      />
      <TextInput
        label="Nazwa"
        placeholder="Wpisz nazwę kategorii"
        value={form.values.name}
        onChange={(event) => {
          form.setFieldValue("name", event.currentTarget.value);
        }}
        error={form.errors.name && "Nazwa jest wymagana"}
      />
      <pre>{JSON.stringify(form.values, null, 2)}</pre>
      <Button type="submit">Zapisz</Button>
    </form>
  );
};
