import { Anchor, Button, Group, Modal, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { AsyncModalResponse, useAsyncModal } from "@zozia/ui";
import * as React from "react";

import {
  useCreateBrandMutation,
  useSearchBrandOnlineQuery,
} from "../features/graphql/graphql";

type NewBrandModalProps = {
  onChange?: (value: string) => void;
  onClick?: () => void;
};
export const NewBrandModal = ({ onChange, onClick }: NewBrandModalProps) => {
  const { mutateAsync: createBrandAsync } = useCreateBrandMutation();

  const waitForData = useAsyncModal({
    closeOnEsc: false,
    closeOnOverlayClick: false,
    defaultResolved: AsyncModalResponse.NO,
    Component({ onResolve }) {
      const form = useForm({
        initialValues: {
          taxId: "",
          country: "pl",
        },
      });
      const { data } = useSearchBrandOnlineQuery(
        {
          input: form.values,
        },
        {
          enabled: form.values.taxId.length === 10,
        },
      );

      return (
        <Modal
          title="Wyszukaj producenta po numerze NIP"
          opened
          onClose={() => onResolve(AsyncModalResponse.NO)}
        >
          <form onSubmit={form.onSubmit((values) => console.log(values))}>
            <TextInput
              description="Dane zostaną pobrane z systemu GUS"
              label="NIP"
              data-autofocus
              {...form.getInputProps("taxId")}
            />

            {data?.searchBrandOnline ? (
              <div>
                <Text>Znaleziono:</Text>
                <Text>Nazwa: {data?.searchBrandOnline.name}</Text>
                <Text>NIP: {data?.searchBrandOnline.nip}</Text>
                <Text>Regon: {data?.searchBrandOnline.regon}</Text>
              </div>
            ) : null}
            <Group justify="space-between" className="mt-4">
              <Button
                disabled={!data?.searchBrandOnline}
                color="red"
                onClick={() =>
                  onResolve({
                    ...data.searchBrandOnline,
                    inputFrom: "BIR",
                  })
                }
              >
                Dodaj
              </Button>
              <Button onClick={() => onResolve(AsyncModalResponse.NO)}>
                Anuluj
              </Button>
            </Group>
          </form>
        </Modal>
      );
    },
  });

  return (
    <Anchor
      onClick={async () => {
        onClick?.();
        const result = await waitForData();

        if (result === AsyncModalResponse.NO) {
          return;
        }

        const { name, ...meta } = result;
        const newBrand = await createBrandAsync({
          input: {
            name,

            meta,
          },
        });

        onChange && onChange(newBrand.createBrand.id);
      }}
    >
      Dodaj nowego producenta po NIP
    </Anchor>
  );
};
