import { Button, Center, Group, Loader, Table } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { LoadMore, TimeAgo } from "@zozia/ui";
import * as React from "react";

import { BrandCombobox } from "../../../components/BrandCombobox";
import { Link } from "../../../components/Link";
import { SearchableBrandNameInput } from "../../../components/SearchableBrandNameInput";
import { TableHeaderSortable } from "../../../components/TableHeaderSortable/TableHeaderSortable";
import {
  OrderBy,
  useInfiniteProductsQuery,
  useUpdateProductMutation,
} from "../../graphql/graphql";
import { ProductsTableActions } from "../components/ProductsTableActions";
import { SearchableCategoriesInput } from "../components/ProductsTableActions/SearchableCategoriesInput";
import { useProductsTableQuerySearch } from "../hooks";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const MultipleProductUpdateTablePage = () => {
  const { updateQuery, query } = useProductsTableQuerySearch();

  const [filters, setFilters] = React.useState([
    { name: "categoryId", value: query.categoryId },
    { name: "brandId", value: query.brandId },
    {
      name: "name/ean",
      value: query.productSearch ? [query.productSearch] : [],
    },
  ]);

  const [orderBy, setOrderBy] = React.useState<OrderBy[]>([
    { field: "updatedAt", descriptor: { sort: "DESC" } },
  ]);

  const updateOrderBy = (orderBy: OrderBy) => {
    setOrderBy((prev) => {
      const existingOrderBy = prev.find(
        (order) => order.field === orderBy.field,
      );

      return [
        ...prev.filter((order) => order.field !== orderBy.field),
        {
          ...orderBy,
          descriptor: {
            sort: existingOrderBy?.descriptor?.sort === "ASC" ? "DESC" : "ASC",
          },
        },
      ];
    });
  };

  const {
    data: infiniteProductsQueryResult,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteProductsQuery(
    {
      input: { filters, orderBy },
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage) =>
        lastPage.products.cursor
          ? {
              input: {
                cursor: lastPage.products.cursor,
                filters,
                orderBy,
              },
            }
          : undefined,
    },
  );

  const { mutate: updateProductMutate } = useUpdateProductMutation();

  const onCategoryChange = React.useCallback(
    (row) =>
      async ({ value: [categoryId] }) => {
        await updateProductMutate({
          input: {
            id: row.id,
            categoryId,
            images: [],
          },
        });
        notifications.show({
          message: `Zapisano kategorię dla produktu ${row.name}`,
          color: "green",
        });
      },
    [],
  );

  const rows = React.useMemo(
    () =>
      infiniteProductsQueryResult?.pages
        .flatMap((p) => p.products.data)
        .map((row) => {
          return (
            <Table.Tr key={row.id}>
              <Table.Td className="w-1/4">
                <Link to={`/collections/products/${row.id}`} fz="sm">
                  {row.name || "Brak nazwy"}
                </Link>
              </Table.Td>
              <Table.Td>{row.ean}</Table.Td>
              <Table.Td>
                <SearchableBrandNameInput
                  label="Marka"
                  selectOnly
                  placeholder="Coca Cola"
                  onChange={async ({ value: [brandNameId] }: string) => {
                    await updateProductMutate({
                      input: {
                        id: row.id,
                        brandNameId,
                        images: [],
                      },
                    });
                    notifications.show({
                      message: `Zapisano markę dla produktu ${row.name}`,
                      color: "green",
                    });
                  }}
                  defaultValue={[row.brandNameId]}
                />
              </Table.Td>
              <Table.Td>
                <SearchableCategoriesInput
                  label="Kategoria"
                  placeholder="Soki"
                  onChange={onCategoryChange(row)}
                  multi={false}
                  defaultValue={[row.categoryId]}
                />
              </Table.Td>
              <Table.Td>
                <BrandCombobox
                  defaultValue={row.brandId}
                  onChange={async (brandId: string) => {
                    await updateProductMutate({
                      input: {
                        id: row.id,
                        brandId,
                        images: [],
                      },
                    });
                    notifications.show({
                      message: `Zapisano producenta dla produktu ${row.name}`,
                      color: "green",
                    });
                  }}
                />
              </Table.Td>
              <Table.Td>
                <TimeAgo value={row.updatedAt} />
              </Table.Td>
            </Table.Tr>
          );
        }),
    [infiniteProductsQueryResult],
  );

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <ProductsTableActions
          subTitle={
            <Group>
              <Button component={Link} to={`/collections/products/new`}>
                Dodaj nowy produkt
              </Button>
              <Button component={Link} to={`/collections/products/newSerial`}>
                Dodaj nowe produkty seryjnie
              </Button>
            </Group>
          }
          title="Edycja wielu produktów"
          defaultValues={filters}
          onFilterChange={(incomingFilters) => {
            incomingFilters = [].concat(incomingFilters).filter(Boolean);

            const exisingFilters = filters.filter(
              (filter) =>
                !(incomingFilters as []).some(
                  (incomingFilter) => filter.name === incomingFilter.name,
                ),
            );

            let newFilters = [...exisingFilters, ...incomingFilters];

            newFilters = newFilters.filter(Boolean);

            updateQuery({
              brandId:
                newFilters.find((f) => f.name === "brandId")?.value ?? [],
              categoryId:
                newFilters.find((f) => f.name === "categoryId")?.value ?? [],
              productSearch:
                newFilters.find((f) => f.name === "name/ean")?.value[0] ?? null,
            });

            setFilters(newFilters);
          }}
        />
      </div>
      {isLoading ? (
        CenterLoader
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Nazwa</Table.Th>
              <Table.Th>EAN</Table.Th>
              <Table.Th>Marka</Table.Th>
              <Table.Th>Kategoria</Table.Th>
              <Table.Th>Producent</Table.Th>
              <TableHeaderSortable
                onChange={(orderBy) => {
                  if (orderBy === "EMPTY") {
                    setOrderBy((prev) =>
                      prev.filter((order) => order.field !== "updatedAt"),
                    );
                  } else {
                    updateOrderBy({
                      field: "updatedAt",
                      descriptor: { sort: orderBy },
                    });
                  }
                }}
                orderBy={
                  orderBy.find((order) => order.field === "updatedAt")
                    ?.descriptor?.sort
                }
              >
                Data aktualizacji
              </TableHeaderSortable>
            </Table.Tr>
          </Table.Thead>
          <tbody>{rows}</tbody>
        </Table>
      )}
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>{CenterLoader}</div>
        </LoadMore>
      ) : null}
    </>
  );
};
