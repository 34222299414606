import {
  Combobox,
  InputBase,
  ScrollArea,
  Tooltip,
  useCombobox,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import * as React from "react";

import { useAdminBrandsQuery } from "../features/graphql/graphql";
import { NewBrandModal } from "./NewBrandModal";

type BrandComboboxProps = {
  defaultValue?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};
export const BrandCombobox = ({
  defaultValue,
  onChange,
  disabled,
}: BrandComboboxProps) => {
  const [value, setValue] = React.useState<string | null>(defaultValue || null);
  const [search, setSearch] = React.useState("");

  const { data } = useAdminBrandsQuery({});

  const brands = data?.adminBrands?.data || [];

  const matchedBrand = brands.find((brand) => brand.id === value);

  React.useEffect(() => {
    if (defaultValue) {
      setSearch(matchedBrand?.name || "");
    }
  }, [brands, defaultValue]);

  const shouldFilterOptions = brands.every((item) => item.name !== search);
  const filteredOptions = shouldFilterOptions
    ? brands.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase().trim()),
      )
    : brands;

  const multiSelectBrands = filteredOptions.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = multiSelectBrands.map((item) => (
    <Combobox.Option value={item.value} key={item.value}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      width={400}
      onOptionSubmit={(val) => {
        setValue(val);
        setSearch(brands.find((brand) => brand.id === val)?.name || "");
        onChange?.(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          disabled={disabled}
          pointer
          label="Producent"
          placeholder="Wybierz producenta"
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(matchedBrand?.name || "");
          }}
          {...(disabled
            ? {
                rightSection: (
                  <Tooltip label="Wartość pochodzi z naszej bazy danych">
                    <IconInfoCircle color="green" />
                  </Tooltip>
                ),
              }
            : {})}
          ref={combobox.searchRef}
        ></InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize type="scroll" mah={200}>
            {options}
          </ScrollArea.Autosize>
        </Combobox.Options>
        <Combobox.Footer>
          <NewBrandModal
            onClick={() => {
              combobox.closeDropdown();
            }}
            onChange={(brandId) => {
              setValue(brandId);
              combobox.closeDropdown();
            }}
          />
        </Combobox.Footer>
      </Combobox.Dropdown>
    </Combobox>
  );
};
