import * as React from "react";
import { Table, Center, Loader } from "@mantine/core";

import { useInfiniteAdminLocationsQuery } from "../../graphql/graphql";
import { LoadMore } from "@zozia/ui";
import { Link } from "../../../components/Link";
import { useLocationsTableQuerySearch } from "../hooks";
import { LocationsTableActions } from "../components/LocationsTableActions";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const LocationsIndexPage = () => {
  const { query, updateQuery } = useLocationsTableQuerySearch();

  const [filters, setFilters] = React.useState([
    {
      name: "name",
      value: query.locationSearch ? [query.locationSearch] : [],
    },
  ]);

  const {
    data: infiniteProductsQueryResult,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteAdminLocationsQuery(
    {
      input: {
        filters: filters.map((filter) => ({
          ...filter,
          value: filter.value.map((a) =>
            a
              .split(" ")
              .map((str) => str.toLowerCase())
              .join("&"),
          ),
        })),
      },
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage) =>
        lastPage.adminLocations.cursor
          ? {
              input: {
                cursor: lastPage.adminLocations.cursor,
                filters,
              },
            }
          : undefined,
    },
  );

  const rows = infiniteProductsQueryResult?.pages
    .flatMap((p) => p.adminLocations.data)
    .map((row) => {
      return (
        <Table.Tr key={row.id}>
          <Table.Td>
            <Link to={`/collections/locations/${row.id}`} fz="sm">
              {row.name}
            </Link>
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <LocationsTableActions
          defaultValues={filters}
          onFilterChange={(incomingFilters) => {
            incomingFilters = [].concat(incomingFilters).filter(Boolean);

            const exisingFilters = filters.filter(
              (filter) =>
                !(incomingFilters as []).some(
                  (incomingFilter) => filter.name === incomingFilter.name,
                ),
            );

            const newFilters = [...exisingFilters, ...incomingFilters]
              .filter(Boolean)
              .filter((filter) => filter.value.every((val) => val.length > 0));

            updateQuery({
              locationSearch:
                newFilters.find((f) => f.name === "name")?.value[0] || null,
            });

            setFilters(newFilters);
          }}
        />
      </div>
      {isLoading ? (
        CenterLoader
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Nazwa</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <tbody>{rows}</tbody>
        </Table>
      )}
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>{CenterLoader}</div>
        </LoadMore>
      ) : null}
    </>
  );
};
