import { useSession } from "@zozia/react-auth";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { user } = useSession();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      setIsLoading(false);
    }
  }, [user, navigate]);

  if (isLoading) {
    return null;
  }

  return children;
};
