import { List, Loader, Switch, Text, Tooltip } from "@mantine/core";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useProductQuery } from "../../../graphql/graphql";

export const AssignedOffers = () => {
  const { productId } = useParams();

  const { isLoading, data } = useProductQuery({
    filters: [{ name: "id", value: productId }],
  });

  if (isLoading) {
    return <Loader />;
  }

  const offers = data.findProduct.offers;

  return (
    <div>
      <Text>Przypisane oferty</Text>
      {offers ? (
        <List>
          {offers.map((offer) => (
            <List.Item key={offer.id}>
              <Text>
                Oferta przypisana do {offer.location.name} -{" "}
                {offer.active ? "aktywna" : "nie aktywna"}
              </Text>
            </List.Item>
          ))}
        </List>
      ) : (
        "No offers"
      )}
    </div>
  );
};
