import { Loader, Title } from "@mantine/core";
import * as React from "react";
import { useParams } from "react-router-dom";

import { queryClient } from "../../../lib/query-client";
import {
  useCategoryQuery,
  useUpdateCategoryMutation,
} from "../../graphql/graphql";
import { UpsertCategoryForm } from "../components/UpsertCategoryForm";

export const ProductCategoryEditIndexPage = () => {
  const { categoryId } = useParams();

  const { data: category, isLoading } = useCategoryQuery({
    categoryId,
  });

  const { mutate } = useUpdateCategoryMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["Category", { categoryId }],
      });
    },
  });

  const handleSubmit = (values) => {
    mutate({
      category: {
        id: categoryId,
        name: values.name,
        parentId: values.parentId,
        taxId: values.taxId,
      },
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Title>Kategorie produktów</Title>
      <UpsertCategoryForm
        defaultValue={category.category}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export const CategoryEditIndexRouteUrl = "product-categories/:categoryId/edit";
