import * as React from "react";
import { Paper, Button, Title, Text, Anchor } from "@mantine/core";
import { useSession } from "@zozia/react-auth";
import { useNavigate } from "react-router-dom";

import styles from "./LoginForm.module.css";
import { Header } from "@zozia/ui";

export function LoginForm() {
  const navigate = useNavigate();
  const { login, user, logout } = useSession();

  React.useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div className={styles.wrapper}>
      <Paper className={styles.form} radius={0} p={30}>
        <Title order={1}>
          <Header>Zozia Admin</Header>
        </Title>
        <Text>Witaj ponownie</Text>

        {user ? (
          <Button fullWidth size="md" onClick={logout}>
            Wyloguj
          </Button>
        ) : (
          <Button fullWidth mt="lg" size="md" onClick={login}>
            Zaloguj się
          </Button>
        )}

        <div className="flex-grow"></div>
        <div className="px-3 text-center">
          <Text fz={13}>
            {`Wersja: ${process.env.META_PROJECT_VERSION}.${process.env.META_IMAGE_VERSION}`}
          </Text>
          <Text fz={10} pt={2}>
            Made with ❤️ by{" "}
            <Anchor
              fz={10}
              c="black"
              underline="never"
              href={`mailto:kontakt@soft-bind.com?body=%0D%0D%0D%0D%0DWersja aplikacji: ${process.env.META_PROJECT_VERSION}.${process.env.META_IMAGE_VERSION} - ${process.env.META_PROJECT_NAME}`}
            >
              Soft Bind
            </Anchor>
          </Text>
        </div>
      </Paper>
    </div>
  );
}
