import * as React from "react";
import { useParams } from "react-router-dom";
import { useProductQuery } from "../../graphql/graphql";
import { Button, Grid, Loader } from "@mantine/core";
import { LocationDetails } from "../components/LocationDetails";
import { LocationUpsertForm } from "../components/LocationUpsertForm";

export const LocationUpsertPage = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const [state, setState] = React.useState<"details" | "update" | "new">(() => {
    return locationId !== "new" ? "details" : "new";
  });

  return (
    <div style={{ padding: "0 8px" }}>
      <Grid>
        <Grid.Col span={6}>
          {(() => {
            switch (state) {
              case "details":
                return (
                  <LocationDetails
                    titleAction={
                      <Button onClick={() => setState("update")}>Edytuj</Button>
                    }
                  />
                );
              case "update":
                return <ProductUpdateForm />;
              case "new":
                return <LocationUpsertForm />;
            }
          })()}
        </Grid.Col>
      </Grid>
    </div>
  );
};

const ProductUpdateForm = () => {
  const { productId } = useParams();

  const { data, status } = useProductQuery({
    filters: [{ name: "id", value: productId }],
  });

  if (status === "loading") {
    return <Loader />;
  }

  const product = data?.findProduct;

  return <LocationUpsertForm initialValues={product} />;
};
