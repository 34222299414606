import * as React from "react";

type CtrlVProps = {
  onCtrlV: (value: string) => void;
};
export const CtrlV = ({ onCtrlV }: CtrlVProps) => {
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
      const code = event.which || event.keyCode;

      let charCode = String.fromCharCode(code).toLowerCase();
      if ((event.ctrlKey || event.metaKey) && charCode === "v") {
        navigator.clipboard.readText().then((clipText) => onCtrlV(clipText));
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return null;
};
