import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CloseButton, Image, Indicator, Text } from "@mantine/core";
import { ConfirmDeleteDialogResponse } from "@zozia/ui";
import * as React from "react";

import { useConfirmDeleteDialog } from "../../../../components/ConfirmDeleteDialog";
import { useDeleteProductImageMutation } from "../../../graphql/graphql";

export const ExistingImage = ({
  file,
  productId,
  order,
}: {
  file: { id: string; url: string };
  productId: string;
  order: number;
}) => {
  const showConfirmDeleteDialog = useConfirmDeleteDialog();
  const { mutate } = useDeleteProductImageMutation();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: file.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Indicator
        key={file.url}
        label={
          <CloseButton
            component="div"
            style={{
              "--_cb-bg": "var(--mantine-color-gray-0)",
            }}
            radius={100}
            onClick={async (e) => {
              e.stopPropagation();
              const result = await showConfirmDeleteDialog();

              if (result === ConfirmDeleteDialogResponse.YES) {
                mutate({
                  input: {
                    productId,
                    imageId: file.id,
                  },
                });
              }
            }}
          />
        }
        style={{
          "--indicator-color": "transparent",
        }}
      >
        <Image maw={240} radius="md" alt="Uploaded image" src={file.url} />
        <Text className="text-center">
          {order === 0 ? "Główne zdjęcie" : `Zdjęcie dodatkowe #${order}`}
        </Text>
      </Indicator>
    </div>
  );
};
