import * as React from "react";
import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Center,
  CloseButton,
  Fieldset,
  Group,
  Indicator,
  List,
  ListItem,
  Loader,
  Modal,
  Paper,
  Stack,
  Switch,
  Text,
  Tooltip,
} from "@mantine/core";

import {
  useAdminContextualDiscoveryQuery,
  useAdminDisconnectContextualDiscoveryCategoryLocationMutation,
  useAdminDisconnectContextualDiscoveryCategoryMutation,
  useAdminToggleContextualDiscoveryActiveMutation,
  useAdminUpsertContextualDiscoveryCategoryMutation,
} from "../../graphql/graphql";
import { useParams } from "react-router-dom";
import { LocationCategorySelect } from "../components/LocationCategorySelect";
import { Link } from "../../../components/Link";
import { ContextualDiscoveryNewCategoryForm } from "../components/ContextualDiscoveryNewCategoryForm";
import { IconX } from "@tabler/icons-react";
import { useConfirmDeleteDialog } from "../../../components/ConfirmDeleteDialog";
import { ConfirmDeleteDialogResponse } from "@zozia/ui";
import { AssignLocationToCategoryForm } from "../components/AssignLocationToCategoryForm";
import { useQueryClient } from "@tanstack/react-query";
import { AssignCategoryToContextualDiscoveryForm } from "../components/AssignCategoryToContextualDiscoveryForm";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const ContextualDiscoveryIndexPage = () => {
  const showConfirmDeleteDialog = useConfirmDeleteDialog();
  const queryClient = useQueryClient();
  const [active, setActive] = React.useState(false);

  const { contextualDiscoveryId } = useParams();
  const { data: business, isLoading } = useAdminContextualDiscoveryQuery({
    contextualDiscoveryId,
  });

  React.useEffect(() => {
    if (business) {
      setActive(business.adminContextualDiscovery.active);
    }
  }, [business]);
  const { mutateAsync: removeCategoryFromContextualDiscoveryAsync } =
    useAdminDisconnectContextualDiscoveryCategoryMutation();
  const { mutateAsync: disconnectLocationFromCategoryAsync } =
    useAdminDisconnectContextualDiscoveryCategoryLocationMutation();
  const { mutateAsync: changeCategoryForContextualDiscoveryAsync } =
    useAdminUpsertContextualDiscoveryCategoryMutation();
  const { mutate: toggleContextualDiscoveryActiveMutate } =
    useAdminToggleContextualDiscoveryActiveMutation({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["AdminContextualDiscovery", { contextualDiscoveryId }],
        });
      },
    });

  const showConfirmToggleContextualDiscoveryActive = useConfirmDeleteDialog({
    Component: ({ onResolve, props }) => {
      const handleYes = () => {
        onResolve(ConfirmDeleteDialogResponse.YES);
      };

      const handleNo = () => {
        onResolve(ConfirmDeleteDialogResponse.NO);
      };

      return (
        <Modal
          withinPortal={false}
          title="Czy na pewno chcesz zmienić widoczność okręgu?"
          opened
          onClose={handleNo}
        >
          <Alert color="red">
            {!props.active ? (
              <>
                <Text>Okrąg będzie aktywny</Text>
                <Text>
                  Klienci aplikacji będą mogli widzieć sklepy w danym okręgu.
                </Text>
              </>
            ) : (
              <>
                <Text>Okrąg będzie nieaktywny</Text>
                <Text>
                  Klienci aplikacji nie będą mogli widzieć sklepów w danym
                  okręgu.
                </Text>
              </>
            )}
          </Alert>
          <Group justify="right">
            <Button color={!props.active ? "green" : "red"} onClick={handleYes}>
              {!props.active ? "Zmień na aktywny" : "Zmień na nieaktywny"}
            </Button>
            <Button onClick={handleNo}>Anuluj</Button>
          </Group>
        </Modal>
      );
    },
  });

  if (isLoading) {
    return CenterLoader;
  }

  const selectedCategoryIds =
    business.adminContextualDiscovery.categories.flatMap(
      (cat) => cat.category.id,
    );

  return (
    <>
      <Fieldset legend="Ustawienia">
        <Switch
          label="Aktywne"
          checked={active}
          onChange={async () => {
            const confirmResponse =
              await showConfirmToggleContextualDiscoveryActive({ active });

            if (confirmResponse === ConfirmDeleteDialogResponse.NO) {
              return;
            }

            toggleContextualDiscoveryActiveMutate({
              id: contextualDiscoveryId,
            });
          }}
        />
      </Fieldset>
      <Group align="flex-start">
        <Stack>
          {business.adminContextualDiscovery.categories.map((category) => (
            <Indicator
              key={category.id}
              label={
                <Tooltip label="Usuń całą kategorię">
                  <CloseButton
                    component="div"
                    style={{
                      "--_cb-bg": "var(--mantine-color-gray-0)",
                    }}
                    radius={100}
                    onClick={async (e) => {
                      e.stopPropagation();
                      const result = await showConfirmDeleteDialog();

                      if (result === ConfirmDeleteDialogResponse.NO) {
                        return;
                      }

                      await removeCategoryFromContextualDiscoveryAsync({
                        contextualDiscoveryCategoryId: category.id,
                      });

                      await queryClient.invalidateQueries({
                        queryKey: [
                          "AdminContextualDiscovery",
                          { contextualDiscoveryId },
                        ],
                      });
                    }}
                  />
                </Tooltip>
              }
              style={{
                "--indicator-color": "transparent",
              }}
            >
              <Paper key={category.id} shadow="xs" withBorder className="p-2">
                <Text>Kategoria:</Text>
                <LocationCategorySelect
                  defaultValue={category.category.id}
                  selectedCategoryIds={selectedCategoryIds}
                  onChange={async (categoryId) => {
                    await changeCategoryForContextualDiscoveryAsync({
                      input: {
                        contextualDiscoveryId,
                        categoryId,
                        contextualDiscoveryCategoryId: category.id,
                      },
                    });
                    await queryClient.invalidateQueries({
                      queryKey: [
                        "AdminContextualDiscovery",
                        { contextualDiscoveryId },
                      ],
                    });
                  }}
                />

                <Text>Podpięte lokalizacje</Text>
                <List listStyleType="none">
                  {category.locations.map((location) => (
                    <ListItem
                      key={location.id}
                      classNames={{ itemLabel: "flex items-center" }}
                    >
                      <Link
                        key={location.id}
                        to={`/collections/locations/${location.id}`}
                        className="flex"
                      >
                        {location.name}
                      </Link>
                      <ActionIcon
                        variant="transparent"
                        onClick={async () => {
                          const confirmResponse =
                            await showConfirmDeleteDialog();

                          if (
                            confirmResponse === ConfirmDeleteDialogResponse.NO
                          ) {
                            return;
                          }

                          await disconnectLocationFromCategoryAsync({
                            input: {
                              contextualDiscoveryCategoryId: category.id,
                              locationId: location.id,
                            },
                          });
                          await queryClient.invalidateQueries({
                            queryKey: [
                              "AdminContextualDiscovery",
                              { contextualDiscoveryId },
                            ],
                          });
                        }}
                      >
                        <IconX />
                      </ActionIcon>
                    </ListItem>
                  ))}
                </List>
                <AssignLocationToCategoryForm
                  initialValues={{ contextualDiscoveryCategoryId: category.id }}
                  onSubmit={() => {
                    queryClient.invalidateQueries({
                      queryKey: [
                        "AdminContextualDiscovery",
                        { contextualDiscoveryId },
                      ],
                    });
                  }}
                />
              </Paper>
            </Indicator>
          ))}
        </Stack>
        <ContextualDiscoveryNewCategoryForm />
        <AssignCategoryToContextualDiscoveryForm
          selectedCategoryIds={selectedCategoryIds}
        />
      </Group>
    </>
  );
};
