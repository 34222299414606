import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { useAdminSearchProductQuery } from "../../../graphql/graphql";

type FetchProductFromGs1Props = {
  gtin: string;
};
export const FetchProductFromGs1 = ({ gtin }: FetchProductFromGs1Props) => {
  const { data } = useAdminSearchProductQuery({ gtin }, { enabled: !!gtin });

  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};
