import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Fieldset,
  Group,
  List,
  Loader,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import * as React from "react";
import { useParams } from "react-router-dom";

import { BrandNameUpsertForm } from "../../../components/BrandNameUpsertForm";
import { Link } from "../../../components/Link";
import { Toggle } from "../../../components/Toggle";
import { queryClient } from "../../../lib/query-client";
import {
  useAdminBrandQuery,
  useAdminUpsertBrandMutation,
} from "../../graphql/graphql";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const BrandIndexEditPage = () => {
  const { brandId } = useParams();
  const { data: brand, isLoading } = useAdminBrandQuery({
    brandId,
  });
  const [newMetadata, setNewMetadata] = React.useState([]);
  const { mutate: upsertBrand } = useAdminUpsertBrandMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["AdminBrand", { brandId: brandId }]);
      setNewMetadata([]);
      notifications.show({
        title: "Zapisano",
        message: "Zapisano zmiany",
        color: "green",
      });
    },
  });
  const [brandMeta, setBrandMeta] = React.useState({});

  const form = useForm({
    initialValues: {},
  });

  React.useEffect(() => {
    if (brand) {
      setBrandMeta({ ...brand.adminBrand.meta });
      form.setValues({
        name: brand.adminBrand.name,
        meta: brand.adminBrand.meta,
      });

      form.resetDirty({
        name: brand.adminBrand.name,
        meta: brand.adminBrand.meta,
      });
    }
  }, [brand]);

  const handleSave = (values) => {
    upsertBrand({
      input: {
        id: brandId,
        name: values.name,
        meta: {
          ...values.meta,
          ...newMetadata.reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {}),
        },
      },
    });
  };

  if (isLoading) {
    return CenterLoader;
  }

  return (
    <Group align="start" grow>
      <Stack>
        <form onSubmit={form.onSubmit(handleSave)}>
          <Stack gap={1} className="w-full">
            <TextInput
              width="100%"
              {...form.getInputProps("name")}
              defaultValue={brand.adminBrand.name}
            />
            <Text size="xs">{brand.adminBrand.id}</Text>
          </Stack>
          <Stack>
            <Fieldset legend="Dane dodatkowe">
              {Object.entries(brandMeta).map(([key, value]) => (
                <Group key={key} wrap="nowrap">
                  <TextInput
                    className="w-full"
                    label={key}
                    defaultValue={value as string}
                    {...form.getInputProps(`meta.${key}`)}
                  />
                  <ActionIcon
                    onClick={() => {
                      const prevMeta = brandMeta;
                      delete prevMeta[key];
                      setBrandMeta(prevMeta);
                      form.setValues({ meta: prevMeta });
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ))}
              {newMetadata.map(([key, value], idx) => (
                <Group key={idx} wrap="nowrap">
                  <TextInput
                    label={
                      <TextInput
                        value={key}
                        onChange={(e) => {
                          setNewMetadata((prev) => {
                            prev.splice(idx, 1, [e.target.value, value]);
                            return [...prev];
                          });
                        }}
                      />
                    }
                    defaultValue={value as string}
                    value={value}
                    onChange={(e) => {
                      setNewMetadata((prev) => {
                        prev.splice(idx, 1, [key, e.target.value]);
                        return [...prev];
                      });
                    }}
                  />
                  <ActionIcon>
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ))}
              <Button
                onClick={() => {
                  setNewMetadata((prev) =>
                    prev.concat([[`new-metadata-${newMetadata.length}`, ""]]),
                  );
                }}
              >
                Dodaj nową metadane
              </Button>
            </Fieldset>
            <Group wrap="nowrap">
              <Button
                color="gray"
                onClick={() => {
                  setBrandMeta({ ...brand.adminBrand.meta });

                  form.setValues({
                    name: brand.adminBrand.name,
                    meta: brand.adminBrand.meta,
                  });

                  form.resetDirty({
                    name: brand.adminBrand.name,
                    meta: brand.adminBrand.meta,
                  });
                }}
              >
                Resetuj
              </Button>
              <Button type="submit" className="w-full">
                Zapisz
              </Button>
            </Group>
          </Stack>
        </form>
        <pre>{JSON.stringify(brand.adminBrand, null, 2)}</pre>
      </Stack>
      <Stack>
        <Stack>
          <Fieldset legend="Nazwy marek">
            <Stack>
              {brand.adminBrand.names.map((name) => (
                <BrandNameUpsertForm key={name.id} name={name} brand={brand} />
              ))}
              <Toggle>
                {({ open, setOpen }) => (
                  <>
                    {open ? (
                      <BrandNameUpsertForm
                        name={""}
                        brand={brand}
                        onBrandUpsertSuccess={setOpen}
                      />
                    ) : null}
                    <Button
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      Dodaj nową nazwę
                    </Button>
                  </>
                )}
              </Toggle>
            </Stack>
          </Fieldset>
          <Fieldset
            legend={
              <Group>
                <Text>Powiązane produkty</Text>
                <Badge>{brand.adminBrand.productCount}</Badge>
              </Group>
            }
          >
            <List type="ordered">
              {brand?.adminBrand.products.map((product) => (
                <List.Item key={product.id}>
                  <Link to={`/collections/products/${product.id}`}>
                    {product.name}
                  </Link>
                </List.Item>
              ))}
            </List>
          </Fieldset>
        </Stack>
      </Stack>
    </Group>
  );
};
