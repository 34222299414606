import {
  AppShell,
  Burger,
  Divider,
  Group,
  Indicator,
  ScrollArea,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAffiliate,
  IconApple,
  IconArrowsExchange,
  IconBadgeTm,
  IconBuildingStore,
  IconCategory,
  IconMail,
  IconMap,
  IconPercentage,
  IconUser,
} from "@tabler/icons-react";
import { Header } from "@zozia/ui";
import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { useAdminProductsToVerifyCountQuery } from "../../features/graphql/graphql";
import { LinksGroup } from "./NavbarLinksGroup";
import { UserButton } from "./UserButton";

export function Shell() {
  const [opened, { toggle }] = useDisclosure();

  const { data, isLoading } = useAdminProductsToVerifyCountQuery();

  const collections = [
    { icon: <IconApple />, label: "Produkty", link: "/collections/products" },
    {
      icon: <IconBadgeTm />,
      label: "Marki",
      link: "/collections/brandNames",
    },
    {
      icon: <IconBadgeTm />,
      label: "Producenci",
      link: "/collections/brands",
    },
    { icon: <IconMail />, label: "Emaile", link: "/collections/emails" },
    {
      icon:
        data?.adminProductsToVerifyCount || 0 > 0 ? (
          <Indicator
            processing={isLoading}
            inline
            label={data?.adminProductsToVerifyCount}
            size={16}
          >
            <IconArrowsExchange />
          </Indicator>
        ) : (
          <IconArrowsExchange />
        ),
      label: "Zmiany w produktach",
      link: "/collections/product-suggestions",
    },
    {
      icon: <IconPercentage />,
      label: "Tax",
      link: "/collections/taxes",
    },
    {
      icon: <IconCategory />,
      label: "Tworzenie Merchanta",
      link: "/collections/merchants/create",
    },
    {
      icon: <IconCategory />,
      label: "Kategorie produktów",
      link: "/collections/product-categories",
    },
    {
      icon: <IconBuildingStore />,
      label: "Lokalizacje",
      link: "/collections/locations",
    },
    {
      icon: <IconAffiliate />,
      label: "Firmy",
      link: "/collections/businesses",
    },
    {
      icon: <IconMap />,
      label: "Okręgi",
      link: "/collections/contextual-discoveries",
    },
    {
      icon: <IconUser />,
      label: "Użytkownicy",
      link: "/collections/users",
    },
  ];

  const links = collections.map((item) => {
    return <LinksGroup key={item.link} {...item} />;
  });

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
        className="w-full"
      >
        <AppShell.Header>
          <Group h="100%" px="md">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            <Link to="/" style={{ textDecoration: "none" }}>
              <Header>Zozia Admin</Header>
            </Link>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar>
          <AppShell.Section grow my="md" component={ScrollArea}>
            {links}
          </AppShell.Section>
          <AppShell.Section>
            <div className="p-3">
              <UserButton />
            </div>
            <Divider />
            <div className="px-3">
              <Text fz={13}>
                {`Wersja: ${process.env.META_PROJECT_VERSION}.${process.env.META_IMAGE_VERSION}`}
              </Text>
            </div>
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </QueryParamProvider>
  );
}
