import useModal, { UseModalOptions } from "use-async-modal";

export enum AsyncModalResponse {
  YES = "YES",
  NO = "NO",
}

export type UseAsyncModalOptions = Omit<
  UseModalOptions<AsyncModalResponse>,
  "Component"
> & {
  Component?: ({ onResolve }) => JSX.Element;
};

export const useAsyncModal = (opt: UseAsyncModalOptions = {}) => {
  return useModal({
    Component: () => null,
    closeOnEsc: false,
    closeOnOverlayClick: false,
    defaultResolved: AsyncModalResponse.NO,
    ...opt,
  });
};
