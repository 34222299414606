import {
  Badge,
  Center,
  Group,
  List,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import * as React from "react";
import { useParams } from "react-router-dom";

import { Link } from "../../../components/Link";
import { useAdminBrandQuery } from "../../graphql/graphql";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const BrandIndexPage = () => {
  const { brandId } = useParams();
  const { data: brand, isLoading } = useAdminBrandQuery({
    brandId,
  });

  if (isLoading) {
    return CenterLoader;
  }

  return (
    <Group align="start">
      <Stack>
        <Group>
          <Stack gap={1}>
            <Title order={3}>{brand.adminBrand.name}</Title>
            <Text size="xs">{brand.adminBrand.id}</Text>
          </Stack>
          <Link to={`/collections/brands/${brand.adminBrand.id}/edit`}>
            <IconEdit />
          </Link>
        </Group>
        <Text>Dane dodatkowe:</Text>
        {Object.entries(brand.adminBrand.meta).map(([key, value]) => (
          <Stack gap={0}>
            <Text size="xs">{key}</Text>
            <Text>{value as React.ReactNode}</Text>
          </Stack>
        ))}
      </Stack>
      <Stack>
        <Group>
          <Text>Powiązane produkty</Text>
          <Badge>{brand.adminBrand.productCount}</Badge>
        </Group>
        <List type="ordered">
          {brand?.adminBrand.products.map((product) => (
            <List.Item key={product.id}>
              <Link to={`/collections/products/${product.id}`}>
                {product.name}
              </Link>
            </List.Item>
          ))}
        </List>
      </Stack>
    </Group>
  );
};
