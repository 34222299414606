import { Table, Title, Text, ActionIcon } from "@mantine/core";
import * as React from "react";
import { intersperse } from "@zozia/std";
import { ConfirmDeleteDialogResponse, TimeAgo } from "@zozia/ui";
import { IconTrash } from "@tabler/icons-react";

import {
  useDeleteCategoryMutation,
  useProductCategoriesQuery,
} from "../../graphql/graphql";
import { useConfirmDeleteDialog } from "../../../components/ConfirmDeleteDialog";
import { queryClient } from "../../../lib/query-client";
import { UpsertCategoryFormModal } from "../components/UpsertCategoryForm/UpsertCategoryFormModal";
import { Link } from "react-router-dom";

const ProductCategoriesTable = () => {
  const { data, isLoading } = useProductCategoriesQuery();

  const { mutate: deleteCategoryMutation } = useDeleteCategoryMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ProductCategories"],
      });
    },
  });
  const confirmDelete = useConfirmDeleteDialog();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const rows = data?.productCategories
    ?.sort((a, b) => b.updatedAt - a.updatedAt)
    .map((category) => {
      const plainCategoryTree =
        category.tree?.map(
          (id) => data.productCategories.find((pc) => pc.id === id).name,
        ) || [];
      const categoryTree = intersperse(
        plainCategoryTree,
        <Text className="px-1">→</Text>,
      );

      return (
        <Table.Tr key={category.id}>
          <Table.Td>
            <Link to={`/collections/product-categories/${category.id}`}>
              {category.name}
            </Link>
          </Table.Td>
          <Table.Td>
            <span className="flex">
              {categoryTree.length > 0 ? categoryTree : "Kategoria główna"}
            </span>
          </Table.Td>
          <Table.Td>
            <TimeAgo value={category.updatedAt} />
          </Table.Td>
          <Table.Td>
            <ActionIcon
              onClick={async () => {
                const result = await confirmDelete();

                if (result === ConfirmDeleteDialogResponse.NO) {
                  return;
                }

                deleteCategoryMutation({
                  categoryId: category.id,
                });
              }}
            >
              <IconTrash />
            </ActionIcon>
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <Table>
      <Table.Thead>
        <Table.Th>Nazwa</Table.Th>
        <Table.Th>Drzewo</Table.Th>
        <Table.Th>Data modyfikacji</Table.Th>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
};

export const ProductCategoriesIndexPage = () => {
  return (
    <>
      <Title>Kategorie produktów</Title>
      <UpsertCategoryFormModal />
      <ProductCategoriesTable />
    </>
  );
};

export const CategoriesIndexRouteUrl = "product-categories";
