import {
  Button,
  Combobox,
  Group,
  InputBase,
  Modal,
  ModalProps,
  ScrollArea,
  Stack,
  Text,
  useCombobox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useProductsQuery } from "../../../graphql/graphql";
import { useTryToCreateProductFromOtherDB } from "../../hooks";

type SearchableEanInputProps = {
  onChange: (categories: { name: string; value: string[] } | null) => void;
  label: string;
  defaultValue?: string;
  placeholder?: string;
};

const DEFAULT_EAN_FILTER = {
  name: "ean",
  value: [],
};

export function SearchableEanInput({
  label,
  onChange,
  defaultValue,
  placeholder,
}: SearchableEanInputProps) {
  const [search, setSearch] = React.useState(defaultValue ?? "");
  const [opened, { close, open }] = useDisclosure(false);
  const [input, setInput] = React.useState({
    gtin: search,
    createOnFind: false,
  });
  const navigate = useNavigate();

  const { data } = useProductsQuery(
    { input: { filters: [{ name: "ean", value: [search || ""] }] } },
    { staleTime: Infinity },
  );
  const products = data?.products.data ?? [];

  const selectProducts = products.map(({ id, name, ean }) => ({
    id,
    label: ean,
    subLabel: name,
    value: ean,
  }));

  const { tryCreateProductFromOtherDb, data: productFromSearch } =
    useTryToCreateProductFromOtherDB(
      {
        input: {
          ...input,
          gtin: search,
        },
      },
      {
        onSuccess: (data) => {
          if (input.createOnFind) {
            navigate(`/products/${data.createProductFromOtherDb.id}`);
          }
        },
      },
    );

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [value, setValue] = React.useState<string[]>(
    defaultValue ? [defaultValue] : [],
  );

  const createProductFromFoundData = async () => {
    setInput({ ...input, createOnFind: true });
    await tryCreateProductFromOtherDb();
  };

  const handleValueSelect = (newValue: string) => {
    setSearch(newValue);

    onChange({ ...DEFAULT_EAN_FILTER, value: [newValue] });
    setValue([newValue]);

    combobox.closeDropdown();
    combobox.targetRef.current?.blur();
  };

  const rawOptions = selectProducts.filter((item) =>
    item.value.toLowerCase().includes(search.trim().toLowerCase()),
  );

  const options = rawOptions.map((item) => {
    const samePart = item.label.indexOf(search);
    const beforePart = item.label.slice(0, samePart);
    return (
      <Combobox.Option
        value={item.value}
        key={item.id}
        active={value.includes(item.value)}
      >
        <Stack gap={0}>
          <Text component="span" size="sm">
            {beforePart ? (
              <Text component="span" fw={600}>
                {beforePart}
              </Text>
            ) : (
              ""
            )}
            <Text component="span" c="yellow" fw={900}>
              {item.label.slice(samePart, samePart + search.length)}
            </Text>

            <Text component="span" fw={600}>
              {item.label.slice(samePart + search.length)}
            </Text>
          </Text>
          <Text size="xs">{item.subLabel}</Text>
        </Stack>
      </Combobox.Option>
    );
  });

  const selectedProduct = products.find(
    (category) => category.ean === value[0],
  );

  const productNotFound = products.find(
    (product) => product.name === "Nowy produkt",
  );

  return (
    <>
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}
      >
        <Combobox.Target>
          <InputBase
            label={label}
            placeholder={placeholder}
            description={
              selectedProduct?.name === "Nowy produkt" ? (
                <>
                  <Text>{search}</Text>
                  <Text c="red">
                    Nie znaleźliśmy produktu o podanym numerze EAN
                  </Text>
                  <Button onClick={open}>
                    Kliknij tutaj aby spróbować wyszukać produktu online
                  </Button>
                </>
              ) : (
                selectedProduct?.name
              )
            }
            value={
              combobox.dropdownOpened ? search : selectedProduct?.ean ?? ""
            }
            onClick={() => {
              combobox.openDropdown();
            }}
            onFocus={() => {
              if (value[0] !== undefined) {
                setSearch(value[0]);
              }
              combobox.openDropdown();
            }}
            onBlur={() => {
              combobox.closeDropdown();
            }}
            onChange={(event) => {
              combobox.updateSelectedOptionIndex();
              setSearch(event.currentTarget.value);
            }}
            onKeyDown={(event) => {
              if (
                event.key === "Enter" &&
                !products.find((p) => p.ean === event.currentTarget.value)
              ) {
                event.preventDefault();
                // handleValueRemove(value[value.length - 1]);
                products.push({
                  name: "Nowy produkt",
                  ean: event.currentTarget.value,
                });
                handleValueSelect(event.currentTarget.value);
              }
            }}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            <ScrollArea.Autosize mah={350} type="scroll">
              {options.length > 0 ? (
                options
              ) : (
                <Combobox.Empty>
                  Nie ma produktu o podanym numerze EAN...
                </Combobox.Empty>
              )}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {opened ? (
        <ModalWithAutoHandler
          onOpen={tryCreateProductFromOtherDb}
          opened={opened}
          onClose={close}
          size="auto"
          title="Wyniki przeszuwania produktu online"
          transitionProps={{ transition: "fade", duration: 200 }}
        >
          <Text>Produkt o numerze EAN {productNotFound.ean}</Text>
          <Text>
            Czy chesz utworzyć produkt automatycznie na bazie danych które
            zostały znalezione?
          </Text>

          <pre>{JSON.stringify(productFromSearch, null, 2)}</pre>

          <Group justify="end">
            <Button color="green" onClick={createProductFromFoundData}>
              Tak
            </Button>
            <Button color="red" onSubmit={() => {}}>
              Nie
            </Button>
          </Group>
        </ModalWithAutoHandler>
      ) : null}
    </>
  );
}

const ModalWithAutoHandler = ({
  ...props
}: ModalProps & { onOpen: () => void }) => {
  React.useEffect(() => {
    props?.onOpen();
  }, []);
  return <Modal {...props} />;
};
