import { CloseButton, MultiSelect, Tooltip } from "@mantine/core";
import * as React from "react";

import { useAdminBrandsQuery } from "../../../graphql/graphql";

type BrandsMultiSelectProps = {
  onChange: (categories: { name: string; value: string[] } | null) => void;
  defaultValue?: string[];
};

const DEFAULT_BRANDS_FILTER = {
  name: "brandId",
  value: [],
};

export const BrandsMultiSelect = ({
  onChange,
  defaultValue,
}: BrandsMultiSelectProps) => {
  const [value, setValue] = React.useState(defaultValue ?? []);
  const { data } = useAdminBrandsQuery({}, { staleTime: Infinity });

  const brands = data?.adminBrands.data || [];

  const multiSelectBrands = brands.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));

  return (
    <MultiSelect
      withCheckIcon
      label="Producenci"
      placeholder="Wybierz producentów"
      data={multiSelectBrands}
      styles={{ wrapper: { maxWidth: 400 } }}
      searchable
      nothingFoundMessage="Nie ma takiego producenta..."
      hidePickedOptions
      value={value}
      onChange={(values) => {
        setValue(values);

        onChange(
          values.length > 0
            ? { ...DEFAULT_BRANDS_FILTER, value: values }
            : DEFAULT_BRANDS_FILTER,
        );
      }}
      rightSection={
        value.length > 0 ? (
          <Tooltip label="Wyczyść wszystkie">
            <CloseButton
              size="sm"
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setValue([]);
                onChange(DEFAULT_BRANDS_FILTER);
              }}
              aria-label="Clear value"
            />
          </Tooltip>
        ) : null
      }
    />
  );
};
