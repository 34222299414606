import * as React from "react";
import { ActionIcon, TextInput, Tooltip } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useDebouncedHandler } from "@zozia/ui";

type ProductSearchInputProps = {
  defaultValue?: string;
  onChange: (categories: { name: string; value: string[] } | null) => void;
};

const DEFAULT_SEARCH_VALUE = "";

export const ProductSearchInput = ({
  onChange,
  defaultValue,
}: ProductSearchInputProps) => {
  const [value, setValue] = React.useState(
    defaultValue || DEFAULT_SEARCH_VALUE,
  );
  const debouncedOnChange = useDebouncedHandler(() => {
    onChange({ name: "name/ean", value: value.length > 0 ? [value] : [] });
  });

  return (
    <TextInput
      label="Wyszukiwarka produktów po EAN lub nazwie"
      placeholder="5449000054227"
      value={value}
      onChange={(event) => {
        setValue(event.currentTarget.value);
        debouncedOnChange();
      }}
      rightSection={
        value.length > 0 ? (
          <Tooltip label="Wyczyść">
            <ActionIcon
              aria-label="Clear all"
              onClick={() => {
                setValue(DEFAULT_SEARCH_VALUE);
                debouncedOnChange();
              }}
            >
              <IconX />
            </ActionIcon>
          </Tooltip>
        ) : null
      }
    />
  );
};
