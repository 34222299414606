import { Button, Flex } from "@mantine/core";
import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  markup_editor_defaults,
  plugin_annotate,
  plugin_crop,
  plugin_filter,
  plugin_filter_defaults,
  plugin_finetune,
  plugin_finetune_defaults, // plugins
  setPlugins,
} from "@pqina/pintura";
import {
  LocaleAnnotate,
  LocaleCore,
  LocaleCrop,
  LocaleFilter,
  LocaleFinetune,
  LocaleMarkupEditor,
} from "@pqina/pintura/locale/en_GB";
// pintura
import "@pqina/pintura/pintura.css";
import * as React from "react";

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const editorDefaults = {
  utils: ["crop", "finetune", "filter", "annotate"],
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...LocaleCore,
    ...LocaleCrop,
    ...LocaleFinetune,
    ...LocaleFilter,
    ...LocaleAnnotate,
    ...LocaleMarkupEditor,
  },
};

export const ImageBgRemoval = ({ onImageReady }) => {
  const [urlStatus, setUrlStatus] = React.useState("");
  const imageContainer = React.useRef<HTMLDivElement>(null);
  const urlRef = React.useRef<HTMLInputElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [file, setFile] = React.useState<File>();

  const onUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let url;
    try {
      new URL(e.target.value);
      url = new URL(
        `${process.env.DATA_SERVICE_REST}/removeBg?url=${e.target.value}&key=super-tajny-klucz-123`,
      );
      setUrlStatus("");

      const res = await fetch(url);
      const blob = await res.blob();

      setFile(new File([blob], `${crypto.randomUUID()}.png`));
      await drawImageOnCanvas(blob);
      urlRef.current.value = "";
    } catch (error) {
      setUrlStatus("Invalid url");
    }
  };

  async function drawImageOnCanvas(image: Blob) {
    const img = new Image();
    img.src = URL.createObjectURL(image);
    img.onload = () => {
      imgRef.current.src = img.src;
    };
  }

  return (
    <div>
      <Flex>
        <input
          ref={urlRef}
          placeholder="Wklej url obrazka tutaj"
          onChange={onUrlChange}
        />
        {urlStatus && <p>{urlStatus}</p>}
      </Flex>
      <Flex>
        <div
          style={{
            width: 350,
            height: 350,
            border: "3px dashed red",
            background: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURb+/v////5nD/3QAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAUSURBVBjTYwABQSCglEENMxgYGAAynwRB8BEAgQAAAABJRU5ErkJggg==")`,
          }}
          ref={imageContainer}
        >
          <img ref={imgRef} style={{ width: "100%", height: "100%" }} />
        </div>
        {file && (
          <Button
            onClick={() => {
              onImageReady(file);
            }}
          >
            Dodaj zdjęcie
          </Button>
        )}
      </Flex>
      {/* <div style={{ height: "70vh" }}>
        <PinturaEditor
          {...editorDefaults}
          src={blob}
          onLoad={(res) => console.log("load image", res)}
          onProcess={({ dest }) => {
            const a = URL.createObjectURL(dest);
            setResult(a);
          }}
        />
      </div> */}
      {/* {processingStatus === "Done!" && (
        <button
          onClick={() => {
            const canvas = document.getElementById(
              "canvas",
            ) as HTMLCanvasElement;
            const link = document.createElement("a");
            link.download = `${crypto.randomUUID()}.png`;
            link.href = canvas.toDataURL("image/png");
            link.click();
          }}
        >
          Pobierz zdjęcie
        </button>
      )} */}
    </div>
  );
};

// fileUpload.addEventListener('change', function (e) {
//     const file = e.target.files[0];
//     if (!file) {
//         return;
//     }

//     const reader = new FileReader();

//     // Set up a callback when the file is loaded
//     reader.onload = e2 => predict(e2.target.result);

//     reader.readAsDataURL(file);
// });

// Predict foreground of the given image
