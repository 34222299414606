import * as React from "react";
import { ActionIcon, Group, Loader, Stack, Title } from "@mantine/core";
import { useCategoryQuery } from "../../graphql/graphql";
import { Link, useParams } from "react-router-dom";
import { IconEdit } from "@tabler/icons-react";

export const ProductCategoryIndexPage = () => {
  const { categoryId } = useParams();
  const { data: category, isLoading } = useCategoryQuery({
    categoryId,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Group>
        <Title>{category.category.name}</Title>
        <ActionIcon
          component={Link}
          to={`/collections/product-categories/${categoryId}/edit`}
        >
          <IconEdit />
        </ActionIcon>
      </Group>
      <pre>{JSON.stringify(category, null, 2)}</pre>
    </>
  );
};

export const CategoryIndexRouteUrl = "product-categories/:categoryId";
