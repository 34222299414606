import * as React from "react";
import { useEmailsQuery } from "../../graphql/graphql";
import { Anchor, ActionIcon, ScrollArea, Table } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

export const EmailsPage = () => {
  const { data } = useEmailsQuery();

  const rows = data?.adminListEmails.map((row) => {
    return (
      <tr key={row.id}>
        <td>
          <Anchor component="button" fz="sm">
            {row.name}
          </Anchor>
        </td>
        <td>{row.subject}</td>
        <td>{row.template}</td>
        <td>
          <ActionIcon>
            <IconEdit />
          </ActionIcon>
        </td>
      </tr>
    );
  });

  return (
    <ScrollArea>
      <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
        <thead>
          <tr>
            <th>Klucz</th>
            <th>Temat</th>
            <th>Treść</th>
            <th>Akcja</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
};
