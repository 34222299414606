import * as React from "react";
import { useInfiniteAdminProductsToVerifyQuery } from "../../graphql/graphql";
import { Table, Badge, Center, Loader, Text } from "@mantine/core";
import { LoadMore, TimeAgo } from "@zozia/ui";
import { Link } from "../../../components/Link";
import { ProductSuggestionsDetailsIndexRouteUrl } from "./ProductSuggestionsDetailsIndexPage";

const ProductSuggestionsTable = () => {
  const { data, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteAdminProductsToVerifyQuery(
      {
        input: {
          filters: [],
        },
      },
      {
        staleTime: 1000 * 60 * 5,
        getNextPageParam: (lastPage) =>
          lastPage.adminProductsToVerify.cursor
            ? {
                input: {
                  cursor: lastPage.adminProductsToVerify.cursor,
                },
              }
            : undefined,
      },
    );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const allLoadedData = data?.pages.flatMap(
    (p) => p.adminProductsToVerify.data,
  );

  const rows = allLoadedData?.map((suggestion) => (
    <Table.Tr key={suggestion.id}>
      <Table.Td>
        <Link
          to={`/collections/${ProductSuggestionsDetailsIndexRouteUrl(
            suggestion.id,
          )}`}
        >
          {suggestion.id}
        </Link>
      </Table.Td>
      <Table.Td>
        <Link to={`/collections/products/${suggestion.product.id}`}>
          {suggestion.product.name}
        </Link>
      </Table.Td>
      <Table.Td className="text-center">
        <Badge size="xs">{suggestion.suggestions.length}</Badge>
      </Table.Td>
      <Table.Td>
        <TimeAgo value={suggestion.createdAt} />
      </Table.Td>
      <Table.Td>
        {(() => {
          switch (suggestion.status) {
            case "APPROVED":
              return <Badge color="green">Zaakceptowany</Badge>;
            case "REJECTED":
              return <Badge color="red">Odrzucony</Badge>;
            default:
              return <Badge color="gray">Oczekujący</Badge>;
          }
        })()}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Text>Ilość: {allLoadedData.length}</Text>
      <Table>
        <Table.Thead>
          <Table.Th>Id</Table.Th>
          <Table.Th>Produkt</Table.Th>
          <Table.Th>Ilość zmian</Table.Th>
          <Table.Th>Data utworzenia</Table.Th>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>{CenterLoader}</div>
        </LoadMore>
      ) : null}
    </>
  );
};

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const ProductSuggestionsIndexPage = () => {
  return (
    <>
      <h1>Sugestie zmian w produktach</h1>
      <ProductSuggestionsTable />
    </>
  );
};

export const ProductSuggestionsIndexRouteUrl = "product-suggestions";
