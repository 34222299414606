import * as React from "react";
import { Box, Card, CloseButton, Group, Title, Tooltip } from "@mantine/core";

import { LocationSearchInput } from "./LocationSearchInput";

type Filter = {
  name: string;
  value: string[];
};

type LocationsTableActionsProps = {
  onFilterChange: (categories: Filter | Filter[] | null) => void;
  defaultValues: {
    name: string;
    value: string[];
  }[];
};

export const LocationsTableActions = ({
  onFilterChange,
  defaultValues = [],
}: LocationsTableActionsProps) => {
  const name = defaultValues.find((item) => item.name === "name");

  console.log(defaultValues);

  return (
    <Box>
      <Title>Lista lokalizacji</Title>
      <Card shadow="sm" padding="xs" radius="md" withBorder>
        <Group align="center" justify="start" gap={2}>
          <Title size="h4">Filtry</Title>
          {defaultValues.some((filter) => filter.value.length > 0) ? (
            <Tooltip label="Wyczyść wszystkie filtry">
              <CloseButton
                size="sm"
                onClick={() => {
                  onFilterChange(
                    defaultValues.map((item) => ({
                      name: item.name,
                      value: [],
                    })),
                  );
                }}
                aria-label="Clear value"
              />
            </Tooltip>
          ) : null}
        </Group>
        <LocationSearchInput
          onChange={onFilterChange}
          defaultValue={name?.value?.[0]}
          key={name?.value?.[0]}
        />
      </Card>
    </Box>
  );
};
