import * as React from "react";
import { Table, Center, Loader, Title, Switch } from "@mantine/core";
import { LoadMore } from "@zozia/ui";

import { Link } from "../../../components/Link";
import { useInfiniteAdminContextualDiscoveriesQuery } from "../../graphql/graphql";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const ContextualDiscoveriesIndexPage = () => {
  const {
    data: infiniteProductsQueryResult,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteAdminContextualDiscoveriesQuery(
    {
      input: {},
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage) =>
        lastPage.adminContextualDiscoveries.cursor
          ? {
              input: {
                cursor: lastPage.adminContextualDiscoveries.cursor,
              },
            }
          : undefined,
    },
  );

  const rows = infiniteProductsQueryResult?.pages
    .flatMap((p) => p.adminContextualDiscoveries.data)
    .map((row) => {
      return (
        <Table.Tr key={row.id}>
          <Table.Td>
            <Link to={`/collections/contextual-discoveries/${row.id}`} fz="sm">
              {row.name}
            </Link>
          </Table.Td>
          <Table.Td>
            <Switch checked={row.active} />
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <Title>Lista okręgów</Title>
      </div>
      {isLoading ? (
        CenterLoader
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Nazwa</Table.Th>
              <Table.Th>Aktywne</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <tbody>{rows}</tbody>
        </Table>
      )}
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>{CenterLoader}</div>
        </LoadMore>
      ) : null}
    </>
  );
};
