import {
  Button,
  Group,
  Modal,
  NumberInput,
  Stack,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ConfirmDeleteDialogResponse } from "@zozia/ui";
import * as React from "react";

type TaxUpsertDialogProps = {
  onResolve: (response: ConfirmDeleteDialogResponse) => void;
  initialValues?: any;
};

export const TaxUpsertDialog = ({
  onResolve,
  props: { initialValues },
}: TaxUpsertDialogProps) => {
  const handleYes = (additional = {}) => {
    onResolve({ confirmResponse: ConfirmDeleteDialogResponse.YES, additional });
  };

  const handleNo = () => {
    onResolve({ confirmResponse: ConfirmDeleteDialogResponse.NO });
  };

  const form = useForm({
    initialValues: initialValues
      ? {
          id: initialValues.id,
          description: initialValues.description,
          rate: initialValues.rate,
        }
      : {
          description: "",
          rate: "",
        },
  });

  const handleFormSubmit = (values) => {
    console.log("values", values);
    handleYes(values);
  };

  const hasInitialValues = Boolean(initialValues);

  return (
    <Modal
      withinPortal={false}
      title={hasInitialValues ? "Edytuj stawkę VAT" : "Dodaj nową stawkę VAT"}
      opened
      onClose={handleNo}
    >
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Stack>
          <Stack>
            <TextInput
              {...form.getInputProps("description")}
              label="Opis"
              required
            />
            <NumberInput {...form.getInputProps("rate")} label="Stawka VAT" />
          </Stack>
          <Group justify="space-between">
            <Button color="red" onClick={handleYes}>
              Przerwij
            </Button>
            <Button type="submit">
              {hasInitialValues ? "Zmień" : "Zapisz"}
            </Button>
            <pre>{JSON.stringify(initialValues, null, 2)}</pre>
            <pre>{JSON.stringify(form.values, null, 2)}</pre>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
