import {
  Badge,
  Button,
  Center,
  Group,
  Loader,
  Table,
  Title,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { ConfirmDeleteDialogResponse, LoadMore } from "@zozia/ui";
import * as React from "react";

import { NewBrandModal, NewBrandModalManual } from "../../../components";
import { useConfirmDeleteDialog } from "../../../components/ConfirmDeleteDialog";
import { Link } from "../../../components/Link";
import {
  useAdminDeleteBrandMutation,
  useInfiniteAdminBrandsQuery,
} from "../../graphql/graphql";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const BrandsIndexPage = () => {
  const {
    data: infiniteProductsQueryResult,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteAdminBrandsQuery(
    {},
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage) =>
        lastPage.adminBrands.cursor
          ? {
              input: {
                cursor: lastPage.adminBrands.cursor,
              },
            }
          : undefined,
    },
  );

  const { mutate: deleteBrand } = useAdminDeleteBrandMutation();

  const confirmDelete = useConfirmDeleteDialog();

  const rows = infiniteProductsQueryResult?.pages
    .flatMap((p) => p.adminBrands.data)
    .map((row) => {
      return (
        <Table.Tr key={row.id}>
          <Table.Td>
            <Link to={`/collections/brands/${row.id}`} fz="sm">
              {row.name}
            </Link>
          </Table.Td>
          <Table.Td>
            {row.names?.map((name) => <Badge key={name.id}>{name.name}</Badge>)}
          </Table.Td>
          <Table.Td>
            <Button
              color="red"
              onClick={async () => {
                const result = await confirmDelete();

                if (result === ConfirmDeleteDialogResponse.YES) {
                  deleteBrand({
                    brandId: row.id,
                  });
                }
              }}
            >
              <IconTrash />
            </Button>
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <>
      <Group style={{ padding: "0 8px" }}>
        <Title>Lista producentów</Title>
        <NewBrandModal />
        <NewBrandModalManual />
      </Group>
      {isLoading ? (
        CenterLoader
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Nazwa</Table.Th>
              <Table.Th>Marki</Table.Th>
              <Table.Th>Akcje</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      )}
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>{CenterLoader}</div>
        </LoadMore>
      ) : null}
    </>
  );
};
