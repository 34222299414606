import * as React from "react";
import { Center, Loader } from "@mantine/core";

import { useAdminBusinessQuery } from "../../graphql/graphql";
import { useParams } from "react-router-dom";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const BusinessIndexPage = () => {
  const { businessId } = useParams();
  const { data: business, isLoading } = useAdminBusinessQuery({
    businessId,
  });

  if (isLoading) {
    return CenterLoader;
  }

  return (
    <div>
      <pre>{JSON.stringify(business, null, 2)}</pre>
    </div>
  );
};
