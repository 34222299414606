import { useTryToFindProductOnlineQuery } from "../../graphql/graphql";

export const useTryToCreateProductFromOtherDB = (
  { input },
  { onSuccess } = {},
) => {
  const { data, refetch } = useTryToFindProductOnlineQuery(
    {
      input,
    },
    {
      enabled: false,
      onSuccess,
    },
  );

  return {
    data,
    tryCreateProductFromOtherDb: refetch,
  };
};
