import { StringParam, useQueryParams } from "use-query-params";

export const useLocationsTableQuerySearch = () => {
  const [query, setValue] = useQueryParams({
    locationSearch: StringParam,
  });

  return {
    query,
    updateQuery: setValue,
  };
};
