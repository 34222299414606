import {
  CloseButton,
  Combobox,
  InputBase,
  Pill,
  PillsInput,
  ScrollArea,
  Stack,
  Text,
  useCombobox,
} from "@mantine/core";
import { Tooltip } from "@mantine/core";
import { IconSelector } from "@tabler/icons-react";
import { LoadMore } from "@zozia/ui";
import * as React from "react";

import { useInfiniteAdminTaxesQuery } from "../../features/graphql/graphql";
import { CenterLoader } from "../CenterLoader";

type SearchableTaxesInputProps = {
  onChange: (categories: { name: string; value: string } | null) => void;
  label: string;
  multi?: boolean;
  defaultValue?: string;
  placeholder?: string;
};

const DEFAULT_CATEGORIES_FILTER = {
  name: "taxId",
  value: "",
};

export const SearchableTaxesInput = React.memo(
  ({
    multi,
    label,
    onChange,
    defaultValue,
    placeholder,
  }: SearchableTaxesInputProps) => {
    const [search, setSearch] = React.useState("");

    const { data, fetchNextPage, hasNextPage } = useInfiniteAdminTaxesQuery(
      {
        input: {
          filters: [
            {
              name: "rate",
              value: [search],
            },
          ],
          orderBy: [],
          cursor: null,
        },
      },
      {
        staleTime: 1000 * 60 * 5,
        getNextPageParam: (lastPage) =>
          lastPage.adminTaxes.cursor
            ? {
                input: {
                  filters: [],
                  orderBy: [],
                  cursor: lastPage.adminTaxes.cursor,
                },
              }
            : undefined,
      },
    );
    const categories = data?.pages.flatMap((p) => p.adminTaxes.data) ?? [];

    const selectCategories = categories
      .filter(Boolean)
      .map(({ id, rate, description }) => ({
        label: `${rate / 100}%`,
        description,
        value: id,
      }));

    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
      onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
    });

    const [value, setValue] = React.useState<string>(defaultValue);

    const handleValueSelect = (newValue: string) => {
      setSearch("");

      onChange({ ...DEFAULT_CATEGORIES_FILTER, value: newValue });
      setValue(newValue);

      if (!multi) {
        combobox.closeDropdown();
        combobox.targetRef.current?.blur();
      }
    };

    const handleValueRemove = (val: string) =>
      setValue((current) => current.filter((v) => v !== val));

    const values = (
      <Pill
        key={value}
        withRemoveButton
        onRemove={() => handleValueSelect(value)}
      >
        {categories.find((category) => category.rate === value)?.rate ?? ""}
      </Pill>
    );

    const options = selectCategories
      .filter((brand) => {
        console.log({ search });
        return (
          brand.label.toLowerCase().includes(search?.toLowerCase() || "") ||
          (brand.description || "")
            .toLowerCase()
            .includes(search?.toLowerCase() || "")
        );
      })
      .map((item) => (
        <Combobox.Option
          value={item.value}
          key={item.value}
          active={value === item.value}
        >
          <Stack gap={0}>
            <Text fw={700} size="sm">
              {item.label}
            </Text>
            <Text size="xs">{item.description}</Text>
          </Stack>
        </Combobox.Option>
      ));

    return (
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} width={400}>
        {multi ? (
          <Combobox.DropdownTarget>
            <PillsInput
              label={label}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              rightSection={
                value.length > 0 ? (
                  <Tooltip label="Wyczyść wszystkie">
                    <CloseButton
                      size="sm"
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => {
                        setValue("");
                        onChange(DEFAULT_CATEGORIES_FILTER);
                      }}
                      aria-label="Clear value"
                    />
                  </Tooltip>
                ) : (
                  <IconSelector size="1rem" />
                )
              }
            >
              <Pill.Group>
                {values}

                <Combobox.EventsTarget>
                  <PillsInput.Field
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                    value={search}
                    placeholder={placeholder}
                    onChange={(event) => {
                      combobox.updateSelectedOptionIndex();
                      setSearch(event.currentTarget.value || "");
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace" && search.length === 0) {
                        event.preventDefault();
                        handleValueRemove(value[value.length - 1]);
                      }
                    }}
                  />
                </Combobox.EventsTarget>
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>
        ) : (
          <Combobox.Target>
            <InputBase
              label={label}
              placeholder={placeholder}
              value={
                combobox.dropdownOpened
                  ? search
                  : selectCategories.find(
                      (category) => category.value === value,
                    )?.label ?? ""
              }
              onClick={() => {
                combobox.openDropdown();
              }}
              onFocus={() => {
                if (value !== undefined) {
                  setSearch(
                    selectCategories.find(
                      (category) => category.value === value,
                    )?.label || "",
                  );
                }
                combobox.openDropdown();
              }}
              onBlur={() => {
                combobox.closeDropdown();
              }}
              onChange={(event) => {
                combobox.updateSelectedOptionIndex();
                setSearch(event.currentTarget.value || "");
              }}
            />
          </Combobox.Target>
        )}

        <Combobox.Dropdown>
          <Combobox.Options>
            <ScrollArea.Autosize mah={350} type="scroll">
              {options.length > 0 ? (
                options
              ) : (
                <Combobox.Empty>Nie ma takiej kategorii...</Combobox.Empty>
              )}
              {hasNextPage ? (
                <LoadMore onIntersect={fetchNextPage}>
                  <div style={{ padding: 30 }}>
                    <CenterLoader />
                  </div>
                </LoadMore>
              ) : null}
            </ScrollArea.Autosize>
          </Combobox.Options>
          {multi ? null : (
            <Combobox.Footer>
              <Text fz="xs" c="dimmed">
                Wybierz stawkę VAT z listy
              </Text>
            </Combobox.Footer>
          )}
        </Combobox.Dropdown>
      </Combobox>
    );
  },
  (prev, next) => prev.defaultValue !== next.defaultValue,
);
