import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import * as React from "react";

import { UpsertCategoryForm } from "./UpsertCategoryForm";
import { useCreateCategoryMutation } from "../../../graphql/graphql";

export const UpsertCategoryFormModal = () => {
  const [opened, { open, close }] = useDisclosure();

  const { mutate } = useCreateCategoryMutation();

  const handleSubmit = (values) => {
    mutate({
      input: {
        name: values.name,
        parentId: values.parentId,
      },
    });
  };

  return (
    <>
      <Button onClick={open}>Dodaj nową kategorię</Button>
      <Modal opened={opened} onClose={close}>
        <div>Modal</div>
        <UpsertCategoryForm handleSubmit={handleSubmit} />
      </Modal>
    </>
  );
};
