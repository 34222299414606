import * as React from "react";
import { useForm } from "@mantine/form";
import { Button, Paper, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useParams } from "react-router-dom";

import { useAdminUpsertContextualDiscoveryCategoryMutation } from "../../../graphql/graphql";
import { LocationCategorySelect } from "../LocationCategorySelect";

export const AssignCategoryToContextualDiscoveryForm = ({
  selectedCategoryIds,
}) => {
  const { contextualDiscoveryId } = useParams();
  const form = useForm({
    initialValues: {
      categoryId: "",
    },
  });

  const { mutateAsync: changeCategoryForContextualDiscoveryAsync } =
    useAdminUpsertContextualDiscoveryCategoryMutation();

  const handleSubmit = async (values) => {
    try {
      await changeCategoryForContextualDiscoveryAsync({
        input: {
          categoryId: values.categoryId,
          contextualDiscoveryId,
        },
      });
      notifications.show({
        color: "green",
        title: "Sukces",
        message: "Przypisano kategorię do odkrycia kontekstowego.",
      });
    } finally {
      form.reset();
    }
  };

  return (
    <Paper shadow="md" withBorder className="p-4">
      <Text>Formularz przypisywania nowej kategorii</Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Text>Kategoria:</Text>
        <LocationCategorySelect
          selectedCategoryIds={selectedCategoryIds}
          onChange={(categoryId) =>
            form.setFieldValue("categoryId", categoryId)
          }
        />
        <Button type="submit" className="mt-2">
          Przypisz
        </Button>
      </form>
    </Paper>
  );
};
