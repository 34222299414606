import { Button, Grid, Loader } from "@mantine/core";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useProductQuery } from "../../graphql/graphql";
import { ProductDetails } from "../components/ProductDetails";
import { ProductUpsertForm } from "../components/ProductUpsertForm";

export const ProductUpsertPage = () => {
  const { productId } = useParams();
  const [state, setState] = React.useState<"details" | "update" | "new">(() => {
    return productId !== "new" ? "details" : "new";
  });

  return (
    <div style={{ padding: "0 8px" }}>
      {(() => {
        switch (state) {
          case "details":
            return (
              <ProductDetails
                titleAction={
                  <Button onClick={() => setState("update")}>Edytuj</Button>
                }
              />
            );
          case "update":
            return <ProductUpdateForm />;
          case "new":
            return <ProductUpsertForm />;
        }
      })()}
    </div>
  );
};

const ProductUpdateForm = () => {
  const { productId } = useParams();

  const { data, status } = useProductQuery({
    filters: [{ name: "id", value: productId }],
  });

  if (status === "loading") {
    return <Loader />;
  }

  const product = data?.findProduct;

  return <ProductUpsertForm initialValues={product} />;
};
