import {
  ActionIcon,
  AspectRatio,
  Box,
  Group,
  Image,
  Loader,
  Paper,
  Text,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { IconListSearch, IconPhoto } from "@tabler/icons-react";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useProductQuery } from "../../../graphql/graphql";

export const ProductDetails = ({ titleAction }) => {
  const { productId } = useParams();

  const { data, status } = useProductQuery({
    filters: [{ name: "id", value: productId }],
  });

  if (status === "loading") {
    return <Loader />;
  }

  const product = data.findProduct;

  return (
    <Box>
      <Group>
        <Title>
          {product.name}
          <Text size="xs">{product.id}</Text>
        </Title>
        {titleAction}
      </Group>
      <Group>
        <Text size="sm">EAN {product.ean || "Brak kodu produktu"}</Text>
        {product.ean ? (
          <ActionIcon.Group>
            <Tooltip label="Wyszukaj zdjęć po EAN">
              <ActionIcon
                variant="default"
                size="lg"
                aria-label="Gallery"
                onClick={() =>
                  open(
                    `https://www.google.pl/search?q=${product.ean}&tbm=isch`,
                    "_blank",
                  )
                }
              >
                <IconPhoto style={{ width: rem(20) }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Wyszukaj produktu po EAN">
              <ActionIcon
                variant="default"
                size="lg"
                aria-label="Settings"
                onClick={() =>
                  open(
                    `https://www.google.pl/search?q=${product.ean}`,
                    "_blank",
                  )
                }
              >
                <IconListSearch style={{ width: rem(20) }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </ActionIcon.Group>
        ) : null}
      </Group>
      <Text>
        Kategoria: {product.category?.name ?? "Brak przypisanej kategorii"}
      </Text>
      <Text>
        Marka: {product.brand?.name ?? "Brak przypisanego producenta"}
      </Text>
      {product.images.map((image) => (
        <Image src={image.url} fit="contain" key={image.url} maw={300} />
      ))}
      <Paper shadow="md" withBorder>
        <Text dangerouslySetInnerHTML={{ __html: product.description }}></Text>
      </Paper>
    </Box>
  );
};
