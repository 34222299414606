import {
  ConfirmDeleteDialogResponse,
  UseConfirmDeleteDialogOptions,
  useConfirmDeleteDialog as useConfirmDeleteDialogBase,
} from "@zozia/ui";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";

export const useConfirmDeleteDialog = (
  opt: UseConfirmDeleteDialogOptions = {},
) => {
  return useConfirmDeleteDialogBase({
    Component: ConfirmDeleteDialog,
    closeOnEsc: false,
    closeOnOverlayClick: false,
    defaultResolved: ConfirmDeleteDialogResponse.NO,
    ...opt,
  });
};
