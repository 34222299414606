import * as React from "react";
import { Box, Title, Group, Loader } from "@mantine/core";
import {
  useAdminLocationQuery,
  useAdminUpsertLocationOwnerMutation,
} from "../../../graphql/graphql";
import { useParams } from "react-router-dom";
import { BusinessOwnerSelect } from "../LocationUpsertForm/BusinessOwnerSelect";

export const LocationDetails = ({ titleAction }) => {
  const { locationId } = useParams();
  const { mutate: upsertLocationOwner } = useAdminUpsertLocationOwnerMutation();

  const { data, status } = useAdminLocationQuery({
    locationId,
  });

  if (status === "loading") {
    return <Loader />;
  }

  const location = data.adminLocation.location;

  return (
    <Box>
      <Group>
        <Title>{location.name}</Title>
        {titleAction}
      </Group>
      <BusinessOwnerSelect
        defaultValue={data.adminLocation.business?.id}
        onChange={({ value: businessId }) => {
          upsertLocationOwner({
            input: {
              locationId,
              businessId,
            },
          });
        }}
        onDelete={({ businessId }) => {
          upsertLocationOwner({
            input: {
              locationId,
              businessId,
            },
          });
        }}
      />
      <pre>{JSON.stringify(data, null, 2)}</pre>
      {/* <Text>
        Kategoria: {product.category?.name ?? "Brak przypisanej kategorii"}
      </Text>
      <Text>
        Marka: {product.brand?.name ?? "Brak przypisanego producenta"}
      </Text>
      {product.images.map((image) => (
        <Image src={image.url} fit="contain" key={image.url} maw={300} />
      ))}
      <Paper shadow="md" withBorder>
        <Text dangerouslySetInnerHTML={{ __html: product.description }}></Text>
      </Paper> */}
    </Box>
  );
};
