import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import openingHours from "opening_hours";
import React from "react";

moment.locale("en");

type Props = {
  value?: string;
  locale?: string;
  theme?: "standard" | "bootstrap";
  onChange?: (value: string) => void;
};

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const OpeningHoursCalendar = (props: Props) => {
  const calendarRef: React.RefObject<FullCalendar> = React.useRef(null);

  const importOSM = (oh: string) => {
    if (oh != "24/7") {
      const ohObject = new openingHours(oh);
      const mom = moment()
        .day("Monday")
        .set("hours", 0)
        .set("minutes", 0)
        .toDate();
      const ohIterator = ohObject.getIterator(mom);
      const iteratorChanges = [];
      let latestChange;
      while (
        ohIterator.advance(
          moment()
            .day("Monday")
            .add(1, "week")
            .set("hours", 23)
            .set("minutes", 59)
            .toDate(),
        )
      ) {
        if (latestChange) {
          if (ohIterator.getState() === false && latestChange.state === true) {
            latestChange.to = ohIterator.getDate();
            iteratorChanges.push(latestChange);
            calendarRef.current.getApi().addEvent({
              start: latestChange.from,
              end: latestChange.to,
            });
            latestChange = null;
          }
        } else {
          latestChange = {
            state: ohIterator.getState(),
            from: ohIterator.getDate(),
          };
        }
      }
    }
  };

  const callChangeCallback = () => {
    if (props.onChange) {
      props.onChange(outputOSM(), props.value);
    }
  };

  React.useEffect(() => {
    if (props.value) {
      importOSM(props.value);
    }

    return () => {
      if (calendarRef.current) {
        calendarRef.current.getApi().removeAllEvents();
      }
    };
  }, [props.value]);

  const outputOSM = () => {
    const events = calendarRef.current.getApi().getEvents();
    console.log(events);

    const timeFrames = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    };
    const stringPieces: string[] = [];
    events.forEach((event) => {
      console.log({ event });
      if (event.start.getDay() === event.end.getDay()) {
        if (
          !timeFrames[
            moment(event.start).format("dddd").toLowerCase()
          ].includes(
            `${moment(event.start).format("HH:mm")}-${moment(event.end).format(
              "HH:mm",
            )}`,
          )
        ) {
          timeFrames[moment(event.start).format("dddd").toLowerCase()].push(
            `${moment(event.start).format("HH:mm")}-${moment(event.end).format(
              "HH:mm",
            )}`,
          );
        }
      } else {
        const momentBegin: moment.Moment = moment(event.start);
        const momentEnd: moment.Moment = moment(event.end);
        if (
          !timeFrames[momentBegin.format("dddd").toLowerCase()].includes(
            `${momentBegin.format("HH:mm")}-24:00`,
          )
        ) {
          timeFrames[momentBegin.format("dddd").toLowerCase()].push(
            `${momentBegin.format("HH:mm")}-24:00`,
          );
        }
        if (
          !timeFrames[momentEnd.format("dddd").toLowerCase()].includes(
            `00:00-${momentEnd.format("HH:mm")}`,
          )
        ) {
          timeFrames[momentEnd.format("dddd").toLowerCase()].push(
            `00:00-${momentEnd.format("HH:mm")}`,
          );
        }
        const numOfInDays: number =
          momentEnd.endOf("day").diff(momentBegin.startOf("day"), "days") - 1;
        for (let i: number = 0; i < numOfInDays; i++) {
          if (
            !timeFrames[
              momentBegin.add(i, "days").format("dddd").toLowerCase()
            ].includes("00:00-24:00")
          ) {
            timeFrames[
              momentBegin.add(i, "days").format("dddd").toLowerCase()
            ].push();
          }
        }
      }
    });
    [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ].map((dayOfWeek: string) => {
      if (timeFrames[dayOfWeek] && timeFrames[dayOfWeek].length >= 1) {
        stringPieces.push(
          `${capitalizeFirstLetter(dayOfWeek.substring(0, 2))} ${timeFrames[
            dayOfWeek
          ].join(",")}`,
        );
      }
    });
    return stringPieces.join("; ");
  };

  return (
    <React.Fragment>
      <FullCalendar
        plugins={[interactionPlugin, dayGridPlugin]}
        ref={calendarRef}
        selectable={true}
        selectOverlap={true}
        eventResize={(eventInfo) => {
          // eventInfo.event.remove();
          callChangeCallback();
        }}
        select={(selectionInfo) => {
          console.log("select");
          const existingEvents = calendarRef.current.getApi().getEvents();
          let conflict: boolean = false;
          existingEvents.forEach((existingEvent) => {
            if (
              moment(selectionInfo.start).isBetween(
                existingEvent.start,
                existingEvent.end,
              ) ||
              moment(selectionInfo.end).isBetween(
                existingEvent.start,
                existingEvent.end,
              ) ||
              moment(existingEvent.start).isBetween(
                selectionInfo.start,
                selectionInfo.end,
              ) ||
              moment(existingEvent.end).isBetween(
                selectionInfo.start,
                selectionInfo.end,
              )
            ) {
              conflict = true;
            }
          });
          if (!conflict) {
            calendarRef.current.getApi().addEvent({
              start: selectionInfo.start,
              end: selectionInfo.end,
            });
            callChangeCallback();
          }
        }}
        locale={props.locale ? props.locale : "en"}
        headerToolbar={false}
        dayHeaderFormat={{
          weekday: "long",
        }}
        themeSystem={props.theme ? props.theme : "standard"}
        eventClick={(eventInfo) => {
          console.log(eventInfo.view.calendar.getEvents());

          eventInfo.event.remove();
          console.log(eventInfo.view.calendar.getEvents());
        }}
        eventOverlap={false}
        editable={true}
        eventRemove={() => {
          callChangeCallback();
        }}
        droppable={false}
        eventTimeFormat={{
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        }}
      />
    </React.Fragment>
  );
};
