import { Button, Center, Group, Loader, Table, Title } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { ConfirmDeleteDialogResponse } from "@zozia/ui";
import * as React from "react";

import { BrandNameUpsertForm } from "../../../components/BrandNameUpsertForm";
import { useConfirmDeleteDialog } from "../../../components/ConfirmDeleteDialog";
import { Link } from "../../../components/Link";
import { Toggle } from "../../../components/Toggle";
import { queryClient } from "../../../lib/query-client";
import {
  useAdminBrandNamesQuery,
  useAdminDeleteBrandNameMutation,
} from "../../graphql/graphql";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const BrandNamesIndexPage = () => {
  const { data: adminBrandNames, isLoading } = useAdminBrandNamesQuery();

  const { mutate: deleteBrandNameMutate } = useAdminDeleteBrandNameMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["AdminBrandNames"]);
    },
  });

  const confirmDelete = useConfirmDeleteDialog();

  const rows = adminBrandNames?.adminBrandNames.map((row) => {
    return (
      <Table.Tr key={row.id}>
        <Table.Td>
          {row.manufacturerId ? (
            <Link to={`/collections/brands/${row.manufacturerId}/edit`} fz="sm">
              {row.name}
            </Link>
          ) : (
            row.name
          )}
        </Table.Td>
        <Table.Td>
          <Link to={`/collections/brands/${row.manufacturerId}`} fz="sm">
            {row.manufacturerId}
          </Link>
        </Table.Td>
        <Table.Td>
          <Button
            color="red"
            onClick={async () => {
              const result = await confirmDelete();

              if (result === ConfirmDeleteDialogResponse.YES) {
                deleteBrandNameMutate({
                  brandNameId: row.id,
                });
              }
            }}
          >
            <IconTrash />
          </Button>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Group style={{ padding: "0 8px" }}>
        <Title>Lista Marek</Title>
        <Toggle>
          {({ open, setOpen }) => (
            <>
              {open ? (
                <BrandNameUpsertForm
                  name={""}
                  brand={{ adminBrand: {} }}
                  onBrandUpsertSuccess={() => {
                    queryClient.invalidateQueries(["AdminBrandNames"]);
                    setOpen();
                  }}
                />
              ) : null}
              <Button
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Dodaj nową nazwę
              </Button>
            </>
          )}
        </Toggle>
      </Group>
      {isLoading ? (
        CenterLoader
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Nazwa</Table.Th>
              <Table.Th>Producent</Table.Th>
              <Table.Th>Akcje</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      )}
    </>
  );
};
