import { Table } from "@mantine/core";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";
import * as React from "react";

import { OrderByDirection } from "../../features/graphql/graphql";

export type OrderByDirectionExtended = OrderByDirection | "EMPTY";

const ORDER_BY_MAP = {
  ASC: <IconArrowUp />,
  DESC: <IconArrowDown />,
} as const;

const ORDER_BY_NEXT = {
  ASC: "DESC",
  DESC: "EMPTY",
  EMPTY: "ASC",
} as const;

type TableHeaderSortableProps = {
  children: React.ReactNode;
  orderBy: OrderByDirectionExtended;
  onChange: (orderBy: OrderByDirectionExtended) => void;
};

export const TableHeaderSortable = ({
  children,
  orderBy,
  onChange,
}: TableHeaderSortableProps) => {
  const nextState = ORDER_BY_NEXT[orderBy];
  return (
    <Table.Th onClick={() => onChange(nextState)}>
      {children}
      {ORDER_BY_MAP[orderBy]}
    </Table.Th>
  );
};
