import * as React from "react";
import { Button, Group, Input, Paper, Text } from "@mantine/core";
import { useForm } from "@mantine/form";

import { useAdminUpsertContextualDiscoveryCategoryLocationMutation } from "../../../graphql/graphql";

export const AssignLocationToCategoryForm = ({ initialValues, onSubmit }) => {
  const { mutateAsync: connectLocationToCategoryAsync } =
    useAdminUpsertContextualDiscoveryCategoryLocationMutation();
  const form = useForm({
    initialValues: initialValues || {
      locationId: "",
    },
  });

  const handleSubmit = async (values) => {
    try {
      await connectLocationToCategoryAsync({
        input: {
          contextualDiscoveryCategoryId: values.contextualDiscoveryCategoryId,
          locationId: values.locationId,
        },
      });
      onSubmit?.();
    } finally {
      form.reset();
    }
  };

  return (
    <Paper withBorder shadow="xs">
      <Text>Dodaj lokalizację</Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group justify="space-between">
          <Input {...form.getInputProps("locationId")} />

          <Button type="submit">Dodaj</Button>
        </Group>
      </form>
    </Paper>
  );
};
