import * as React from "react";
import { Group, NavLink } from "@mantine/core";

type Link = {
  label: string;
  link: string;
};

type LinksGroupProps = {
  link?: string;
  label: string;
  links?: Link[];
  icon: React.ReactNode;
};

export function LinksGroup({ link, links, label, icon }: LinksGroupProps) {
  const items = (links ? links : []).map((link) => (
    <NavLink
      key={link.link}
      href={link.link}
      label={
        <Group>
          {icon}
          {link.label}
        </Group>
      }
    />
  ));

  return link ? (
    <NavLink
      classNames={{
        body: "overflow-visible",
        label: "items-center flex",
      }}
      href={link}
      label={
        <Group>
          {icon}
          {label}
        </Group>
      }
    >
      {items.length > 0 ? items : null}
    </NavLink>
  ) : (
    <NavLink label={label}>{items.length > 0 ? items : null}</NavLink>
  );
}
