import {
  Combobox,
  InputBase,
  Loader,
  Pill,
  PillsInput,
  ScrollArea,
  Text,
  useCombobox,
} from "@mantine/core";
import * as React from "react";

import { useAdminBrandNamesQuery } from "../features/graphql/graphql";

type SearchableBrandNameInputProps = {
  onChange: (categories: { name: string; value: string[] } | null) => void;
  onNew: (name: string) => void;
  label: string;
  multi?: boolean;
  defaultValue?: string[];
  placeholder?: string;
};

const DEFAULT_CATEGORIES_FILTER = {
  name: "brandName",
  value: [],
};

export const SearchableBrandNameInput = React.memo(
  ({
    selectOnly,
    label,
    onChange,
    onNew,
    defaultValue,
    placeholder,
  }: SearchableBrandNameInputProps) => {
    console.log(defaultValue);
    const { data, isLoading } = useAdminBrandNamesQuery({});
    const [search, setSearch] = React.useState("");

    const brands = data?.adminBrandNames ?? [];

    const mappedBrands = brands
      .filter((brand) => {
        console.log(brand);
        return brand.name.toLowerCase().includes(search.toLowerCase());
      })
      .filter(({ manufacturerId }) => selectOnly || !manufacturerId)
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }));

    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
      onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
    });

    const [value, setValue] = React.useState<string[]>(defaultValue ?? []);

    const handleValueSelect = (newValue: string) => {
      setSearch("");

      onChange({ ...DEFAULT_CATEGORIES_FILTER, value: [newValue] });
      setValue([newValue]);

      combobox.closeDropdown();
      combobox.targetRef.current?.blur();
    };

    const options = mappedBrands.map((item) => (
      <Combobox.Option value={item.value} key={item.value}>
        <Text size="sm">{item.label}</Text>
      </Combobox.Option>
    ));

    return isLoading ? (
      <Loader />
    ) : (
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} width={400}>
        <Combobox.Target>
          <InputBase
            label={label}
            placeholder={placeholder}
            value={
              combobox.dropdownOpened
                ? search
                : brands.find((category) => category.id === value[0])?.name ??
                  ""
            }
            onClick={() => {
              combobox.openDropdown();
            }}
            onFocus={() => {
              if (value[0] !== undefined) {
                setSearch(
                  brands.find((category) => category.id === value[0])?.name ||
                    "",
                );
              }
              combobox.openDropdown();
            }}
            onBlur={() => {
              combobox.closeDropdown();
              setSearch("");
            }}
            onChange={(event) => {
              combobox.updateSelectedOptionIndex();
              setSearch(event.currentTarget.value);
            }}
            onKeyDown={(event) => {
              if (
                event.key === "Enter" &&
                !mappedBrands.find((p) => p.label === event.currentTarget.value)
              ) {
                event.preventDefault();
                onNew(event.currentTarget.value);
              }
            }}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            <ScrollArea.Autosize mah={350} type="scroll">
              {options.length > 0 ? (
                options
              ) : (
                <Combobox.Empty>Nie ma takiej marki...</Combobox.Empty>
              )}
            </ScrollArea.Autosize>
          </Combobox.Options>
          <Combobox.Footer>
            <Text fz="xs" c="dimmed">
              Wbierz istniejącą lub stwórz nową markę
            </Text>
          </Combobox.Footer>
        </Combobox.Dropdown>
      </Combobox>
    );
  },
);
