import * as React from "react";
import { ActionIcon, TextInput, Tooltip } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useDebouncedHandler } from "@zozia/ui";

type LocationSearchInputProps = {
  defaultValue?: string;
  onChange: (categories: { name: string; value: string[] } | null) => void;
};

const DEFAULT_SEARCH_VALUE = "";

export const LocationSearchInput = ({
  onChange,
  defaultValue,
}: LocationSearchInputProps) => {
  const [value, setValue] = React.useState(
    defaultValue || DEFAULT_SEARCH_VALUE,
  );

  const onChangeHandler = () =>
    onChange({
      name: "name",
      value: value.length > 0 ? [value] : [],
    });

  const debouncedOnChange = useDebouncedHandler(onChangeHandler);

  return (
    <TextInput
      label="Wyszukiwarka lokalizacji po nazwie"
      placeholder="Papuga Zoo Sklep Zoologiczny"
      value={value}
      onChange={(event) => {
        setValue(event.currentTarget.value);
        debouncedOnChange();
      }}
      rightSection={
        value.length > 0 ? (
          <Tooltip label="Wyczyść">
            <ActionIcon
              aria-label="Clear all"
              onClick={() => {
                setValue(DEFAULT_SEARCH_VALUE);
                onChange({
                  name: "name",
                  value: [DEFAULT_SEARCH_VALUE],
                });
              }}
            >
              <IconX />
            </ActionIcon>
          </Tooltip>
        ) : null
      }
    />
  );
};
