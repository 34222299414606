import { Avatar, AvatarGroup, Table } from "@mantine/core";
import { LoadMore, TimeAgo } from "@zozia/ui";
import * as React from "react";

import { CenterLoader } from "../../../components";
import { Link } from "../../../components/Link";
import { TableHeaderSortable } from "../../../components/TableHeaderSortable/TableHeaderSortable";
import { OrderBy, useInfiniteAdminUsersQuery } from "../../graphql/graphql";
import { useUsersTableQuerySearch } from "../hooks";

export const UsersPage = () => {
  const { query, updateQuery } = useUsersTableQuerySearch();

  const [filters, setFilters] = React.useState([
    { name: "name", value: query.name },
  ]);

  const [orderBy, setOrderBy] = React.useState<OrderBy[]>([
    { field: "updatedAt", descriptor: { sort: "DESC" } },
  ]);

  const {
    data: infiniteUsersQueryResult,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteAdminUsersQuery(
    {
      input: { filters, orderBy, cursor: null },
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage) =>
        lastPage.adminUsers.cursor
          ? {
              input: {
                filters,
                orderBy,
                cursor: lastPage.adminUsers.cursor,
              },
            }
          : undefined,
    },
  );

  const updateOrderBy = (orderBy: OrderBy) => {
    setOrderBy((prev) => {
      const existingOrderBy = prev.find(
        (order) => order.field === orderBy.field,
      );

      return [
        ...prev.filter((order) => order.field !== orderBy.field),
        {
          ...orderBy,
          descriptor: {
            sort: existingOrderBy?.descriptor?.sort === "ASC" ? "DESC" : "ASC",
          },
        },
      ];
    });
  };

  const rows = infiniteUsersQueryResult?.pages
    .flatMap((p) => p.adminUsers.data)
    .map((row) => {
      return (
        <Table.Tr key={row.id}>
          <Table.Td>
            <AvatarGroup>
              {row.avatar ? <Avatar src={row.avatar} /> : <Avatar>Brak</Avatar>}
            </AvatarGroup>
          </Table.Td>
          <Table.Td>
            <Link to={`/collections/users/${row.id}`} fz="sm">
              {row.email || "Brak nazwy"}
            </Link>
          </Table.Td>
          <Table.Td>{row.email}</Table.Td>
          <Table.Td>
            <TimeAgo value={row.updatedAt} />
          </Table.Td>
          <Table.Td>{row.role}</Table.Td>
          <Table.Td>
            <TimeAgo value={row.createdAt} />
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <div>
      <pre>{JSON.stringify(orderBy, null, 2)}</pre>
      <pre>{JSON.stringify(filters, null, 2)}</pre>
      {isLoading ? (
        <CenterLoader />
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th>Nazwa</Table.Th>
              <Table.Th>EAN</Table.Th>
              <TableHeaderSortable
                onChange={(orderBy) => {
                  if (orderBy === "EMPTY") {
                    setOrderBy((prev) =>
                      prev.filter((order) => order.field !== "updatedAt"),
                    );
                  } else {
                    updateOrderBy({
                      field: "updatedAt",
                      descriptor: { sort: orderBy },
                    });
                  }
                }}
                orderBy={
                  orderBy.find((order) => order.field === "updatedAt")
                    ?.descriptor?.sort
                }
              >
                Data aktualizacji
              </TableHeaderSortable>
              <TableHeaderSortable
                onChange={(orderBy) => {
                  if (orderBy === "EMPTY") {
                    setOrderBy((prev) =>
                      prev.filter((order) => order.field !== "role"),
                    );
                  } else {
                    updateOrderBy({
                      field: "role",
                      descriptor: { sort: orderBy },
                    });
                  }
                }}
                orderBy={
                  orderBy.find((order) => order.field === "role")?.descriptor
                    ?.sort
                }
              >
                Rola
              </TableHeaderSortable>
              <TableHeaderSortable
                onChange={(orderBy) => {
                  if (orderBy === "EMPTY") {
                    setOrderBy((prev) =>
                      prev.filter((order) => order.field !== "createdAt"),
                    );
                  } else {
                    updateOrderBy({
                      field: "createdAt",
                      descriptor: { sort: orderBy },
                    });
                  }
                }}
                orderBy={
                  orderBy.find((order) => order.field === "createdAt")
                    ?.descriptor?.sort
                }
              >
                Data utworzenia
              </TableHeaderSortable>
            </Table.Tr>
          </Table.Thead>
          <tbody>{rows}</tbody>
        </Table>
      )}
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>
            <CenterLoader />
          </div>
        </LoadMore>
      ) : null}
    </div>
  );
};
