import * as React from "react";
import { Button, Group, Modal } from "@mantine/core";
import { ConfirmDeleteDialogResponse } from "@zozia/ui";

type ConfirmDeleteDialogProps = {
  onResolve: (response: ConfirmDeleteDialogResponse) => void;
};

export const ConfirmDeleteDialog = ({
  onResolve,
}: ConfirmDeleteDialogProps) => {
  const handleYes = () => {
    onResolve(ConfirmDeleteDialogResponse.YES);
  };

  const handleNo = () => {
    onResolve(ConfirmDeleteDialogResponse.NO);
  };

  return (
    <Modal
      withinPortal={false}
      title="Czy na pewno chcesz usunąć?"
      opened
      onClose={handleNo}
    >
      <Group justify="right">
        <Button color="red" onClick={handleYes}>
          Usuń
        </Button>
        <Button onClick={handleNo}>Przerwij</Button>
      </Group>
    </Modal>
  );
};
