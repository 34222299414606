import * as React from "react";
import {
  InputBase,
  Combobox,
  useCombobox,
  ScrollArea,
  Avatar,
  Group,
  CloseButton,
  Tooltip,
} from "@mantine/core";
import { useAdminBusinessOwnersQuery } from "../../../graphql/graphql";
import { useConfirmDeleteDialog } from "../../../../components/ConfirmDeleteDialog";
import { ConfirmDeleteDialogResponse } from "@zozia/ui";

export const BusinessOwnerSelect = ({ defaultValue, onChange, onDelete }) => {
  const showConfirmDeleteDialog = useConfirmDeleteDialog();

  const [search, setSearch] = React.useState("");
  const { data } = useAdminBusinessOwnersQuery({
    input: {
      searchName: "",
    },
  });

  const brands = data?.adminBusinessOwners || [];

  const multiSelectBrands = brands.map((item) => ({
    avatar: item.avatar,
    value: item.businessId,
    label: item.email,
  }));

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch("");
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const [value, setValue] = React.useState<string | null>(defaultValue ?? null);

  const options = multiSelectBrands
    .filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase().trim()),
    )
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value}>
        <Group>
          <Avatar src={item.avatar} />
          {item.label}
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        setValue(val);

        onChange({
          name: "businessOwnerId",
          value: val,
        });
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          pointer
          label="Producent"
          placeholder="Wybierz producenta"
          value={
            multiSelectBrands.find((brand) => brand.value === value)?.label ||
            search
          }
          onChange={(event) => setSearch(event.currentTarget.value)}
          rightSection={
            value ? (
              <Tooltip label="Usuń właściciela lokalizacji">
                <CloseButton
                  size="sm"
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={async () => {
                    const confirmResponse = await showConfirmDeleteDialog();

                    if (confirmResponse === ConfirmDeleteDialogResponse.NO) {
                      return;
                    }

                    await onDelete({
                      businessId: value,
                    });

                    setValue("");
                    onChange({
                      name: "businessOwnerId",
                      value: "",
                    });
                  }}
                  aria-label="Clear value"
                />
              </Tooltip>
            ) : (
              <Combobox.Chevron />
            )
          }
          onClick={() => combobox.toggleDropdown()}
        ></InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize type="scroll" mah={200}>
            {options}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
