import * as React from "react";

import { useIntersectionObserver } from "../hooks";

type LoadMoreProps = {
  children: React.ReactNode;
  onIntersect: () => void;
};

export const LoadMore = ({ children, onIntersect }: LoadMoreProps) => {
  const ref = React.useRef(null);
  const intersectionObserverEntry = useIntersectionObserver(ref, {});

  React.useEffect(() => {
    if (intersectionObserverEntry && intersectionObserverEntry.isIntersecting) {
      console.log("intersecting");
      onIntersect();
    }
  }, [intersectionObserverEntry]);

  return (
    <div ref={ref} style={{ padding: 1 }}>
      {intersectionObserverEntry?.isIntersecting ? children : null}
    </div>
  );
};
