import "@mantine/core/styles.layer.css";
import "@mantine/notifications/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./lib/i18n";
import "./main.css";

const rootElement = document.getElementById("app");
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

globalThis.__ZOZIA__ = {
  meta: {
    META_PROJECT_VERSION: process.env.META_PROJECT_VERSION,
    META_PROJECT_NAME: process.env.META_PROJECT_NAME,
    META_IMAGE_VERSION: process.env.META_IMAGE_VERSION,
  },
};
