import { Switch } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { useUpdateProductMutation } from "../../graphql/graphql";

export const ProductActiveSwitch = ({ productId, checked }) => {
  const client = useQueryClient();

  const { mutate } = useUpdateProductMutation({
    onMutate: ({ input: { active } }) => {
      const data = client.getQueryData(["AdminProducts.infinite"], {
        exact: false,
      });

      console.log(data);

      let pageIdx = 0;
      data.pages.forEach((page, idx) => {
        const product = page.adminProducts.data.find(
          (product) => product.id === productId,
        );

        if (product) {
          pageIdx = idx;
        }
      });

      const a = data.pages[pageIdx].adminProducts.data.map((product) => {
        if (product.id === productId) {
          return { ...product, active };
        }

        return product;
      });

      console.log(pageIdx);

      client.setQueriesData(
        {
          queryKey: ["AdminProducts.infinite"],
          exact: false,
        },
        (data) => {
          return {
            pageParams: data.pageParams,
            pages: data.pages.map((page, idx) => {
              if (idx === pageIdx) {
                return {
                  adminProducts: {
                    data: a,
                    cursor: page.adminProducts.cursor,
                  },
                };
              }

              return page;
            }),
          };
        },
      );

      return () => {
        client.setQueriesData(
          {
            queryKey: ["AdminProducts.infinite"],
            exact: false,
          },
          data,
        );
      };
    },
    onError: (_error, _variables, restore) => {
      restore?.();
    },
    onSettled: () => {
      client.invalidateQueries({
        queryKey: ["AdminProducts.infinite"],
        exact: false,
      });
    },
  });

  return (
    <Switch
      size="xs"
      checked={checked}
      onChange={(event) => {
        mutate({
          input: { id: productId, active: event.currentTarget.checked },
        });
      }}
    />
  );
};
