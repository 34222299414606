import { NumberInput } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import { useObservable, useSubscription } from "observable-hooks";
import * as React from "react";
import {
  buffer,
  filter,
  fromEvent,
  interval,
  map,
  switchMap,
  takeWhile,
  tap,
} from "rxjs";

type BarcodeScannerInputProps = {
  placeholder?: string;
  onChange: (ean: string) => void;
};

const BarcodeScanner = React.forwardRef(
  ({ placeholder, onChange }: BarcodeScannerInputProps, forwardRef) => {
    const numberInputRef = React.useRef<HTMLInputElement>(null);
    const mergedRef = useMergedRef(numberInputRef, forwardRef);

    const up$ = useObservable(() =>
      fromEvent<React.KeyboardEvent<HTMLInputElement>>(document, "keyup").pipe(
        filter((e) => (+e.key >= 0 && +e.key <= 9) || e.code === "Enter"),
      ),
    );

    const enter$ = useObservable(() =>
      up$.pipe(filter((e) => e.code === "Enter")),
    );

    const eanFromBarcodeScanner$ = useObservable(() =>
      up$.pipe(
        buffer(enter$),
        tap(() => {
          numberInputRef.current.value = "";
        }),

        map((e) => e.slice(0, -1)),
        filter((e) => e.length > 0),
        map((e) => e.map((e) => e.key).join("")),
      ),
    );

    useSubscription(eanFromBarcodeScanner$, onChange);
    useSubscription(
      eanFromBarcodeScanner$.pipe(
        switchMap((ean) =>
          interval(25).pipe(
            takeWhile((a) => a <= ean.length),
            tap((a) => {
              numberInputRef.current.value = ean.slice(0, a);
            }),
          ),
        ),
      ),
    );

    return <NumberInput readOnly placeholder={placeholder} ref={mergedRef} />;
  },
);

BarcodeScanner.displayName = "BarcodeScannerInput";

export const BarcodeScannerInput = React.memo(BarcodeScanner);
