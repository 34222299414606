import { ActionIcon, Button, Table } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { LoadMore, TimeAgo } from "@zozia/ui";
import * as React from "react";

import { CenterLoader } from "../../../components";
import { TableHeaderSortable } from "../../../components/TableHeaderSortable/TableHeaderSortable";
import { OrderBy, useInfiniteAdminTaxesQuery } from "../../graphql/graphql";
import { TaxUpsertModal } from "../components/TaxUpsertModal";

export const TaxesPage = () => {
  const [filters, setFilters] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState<OrderBy[]>([
    { field: "updatedAt", descriptor: { sort: "DESC" } },
    { field: "rate", descriptor: { sort: "DESC" } },
  ]);

  const updateOrderBy = (orderBy: OrderBy) => {
    setOrderBy((prev) => {
      const existingOrderBy = prev.find(
        (order) => order.field === orderBy.field,
      );

      return [
        ...prev.filter((order) => order.field !== orderBy.field),
        {
          ...orderBy,
          descriptor: {
            sort: existingOrderBy?.descriptor?.sort === "ASC" ? "DESC" : "ASC",
          },
        },
      ];
    });
  };

  const { data, fetchNextPage, hasNextPage } = useInfiniteAdminTaxesQuery(
    {
      input: { filters, orderBy, cursor: null },
    },
    {
      staleTime: 1000 * 60 * 5,
      getNextPageParam: (lastPage) =>
        lastPage.adminTaxes.cursor
          ? {
              input: {
                filters,
                orderBy,
                cursor: lastPage.adminTaxes.cursor,
              },
            }
          : undefined,
    },
  );

  const rows = data?.pages
    .flatMap((p) => p.adminTaxes.data)
    .map((row) => {
      return (
        <Table.Tr key={row.id}>
          <Table.Td>{row.id}</Table.Td>
          <Table.Td>{row.rate / 100}%</Table.Td>
          <Table.Td>{row.description}</Table.Td>
          <Table.Td>
            <TimeAgo value={row.updatedAt} />
          </Table.Td>
          <Table.Td>
            <TimeAgo value={row.createdAt} />
          </Table.Td>
          <Table.Td>
            <TaxUpsertModal
              trigger={
                <ActionIcon>
                  <IconEdit />
                </ActionIcon>
              }
              initialValues={row}
            />
          </Table.Td>
        </Table.Tr>
      );
    });

  return (
    <div>
      <h1>Stawki VAT</h1>
      <TaxUpsertModal trigger={<Button>Dodaj nową stawkę VAT</Button>} />
      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <TableHeaderSortable
              onChange={(orderBy) => {
                if (orderBy === "EMPTY") {
                  setOrderBy((prev) =>
                    prev.filter((order) => order.field !== "rate"),
                  );
                } else {
                  updateOrderBy({
                    field: "rate",
                    descriptor: { sort: orderBy },
                  });
                }
              }}
              orderBy={
                orderBy.find((order) => order.field === "rate")?.descriptor
                  ?.sort
              }
            >
              Stawka VAT
            </TableHeaderSortable>
            <Table.Th>Opis</Table.Th>
            <TableHeaderSortable
              onChange={(orderBy) => {
                if (orderBy === "EMPTY") {
                  setOrderBy((prev) =>
                    prev.filter((order) => order.field !== "updatedAt"),
                  );
                } else {
                  updateOrderBy({
                    field: "updatedAt",
                    descriptor: { sort: orderBy },
                  });
                }
              }}
              orderBy={
                orderBy.find((order) => order.field === "updatedAt")?.descriptor
                  ?.sort
              }
            >
              Data aktualizacji
            </TableHeaderSortable>
            <TableHeaderSortable
              onChange={(orderBy) => {
                if (orderBy === "EMPTY") {
                  setOrderBy((prev) =>
                    prev.filter((order) => order.field !== "createdAt"),
                  );
                } else {
                  updateOrderBy({
                    field: "createdAt",
                    descriptor: { sort: orderBy },
                  });
                }
              }}
              orderBy={
                orderBy.find((order) => order.field === "createdAt")?.descriptor
                  ?.sort
              }
            >
              Data utworzenia
            </TableHeaderSortable>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      {hasNextPage ? (
        <LoadMore onIntersect={fetchNextPage}>
          <div style={{ padding: 30 }}>
            <CenterLoader />
          </div>
        </LoadMore>
      ) : null}
    </div>
  );
};
