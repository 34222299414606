import useModal, { UseModalOptions } from "use-async-modal";
import { ConfirmDeleteDialogResponse } from "./ConfirmDeleteDialog";

export type UseConfirmDeleteDialogOptions = Omit<
  UseModalOptions<ConfirmDeleteDialogResponse>,
  "Component"
> & {
  Component?: ({ onResolve }) => JSX.Element;
};

export const useConfirmDeleteDialog = (
  opt: UseConfirmDeleteDialogOptions = {},
) => {
  return useModal({
    Component: () => null,
    closeOnEsc: false,
    closeOnOverlayClick: false,
    defaultResolved: ConfirmDeleteDialogResponse.NO,
    ...opt,
  });
};
