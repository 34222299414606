import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import {
  ConfirmDeleteDialogResponse,
  UseConfirmDeleteDialogOptions,
  useConfirmDeleteDialog,
} from "@zozia/ui";
import * as React from "react";

import { useAdminAddTaxMutation } from "../../../graphql/graphql";
import { TaxUpsertDialog } from "./TaxUpsertDialog";

type TaxUpsertModalProps = {
  initialValues?: any;
  trigger: React.ReactElement;
};
export const TaxUpsertModal = ({
  initialValues,
  trigger: Trigger,
}: TaxUpsertModalProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useAdminAddTaxMutation({
    onSuccess: () => {
      notifications.show({
        title: "Stawka VAT została dodana",
        message: "Stawka VAT została dodana",
        color: "blue",
      });
      queryClient.invalidateQueries(["AdminTaxes.infinite"]);
    },
  });
  const upsertDialogModal = useConfirmDeleteDialog({
    Component: TaxUpsertDialog,
    closeOnEsc: false,
    closeOnOverlayClick: false,
    defaultResolved: ConfirmDeleteDialogResponse.NO,
  });

  const onClick = async () => {
    const { confirmResponse, additional: formValues } = await upsertDialogModal(
      { initialValues },
    );

    if (confirmResponse === ConfirmDeleteDialogResponse.YES) {
      mutate({ input: formValues });
    }
  };

  return React.cloneElement(Trigger, { onClick });
};
